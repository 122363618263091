// @ts-nocheck
/* eslint-disable */

import styled from 'styled-components/macro'
import { memo } from 'react'
import { fontFamily } from '../../../../core/styles/mixins'

const Styled = styled.div`
  font-family: 'Neue Haas Grotesk Display Pro';
  text-align: justify;
  a {
    text-decoration: none;
  }
  
  p.MsoNormal, li.MsoNormal, div.MsoNormal {
    text-align: justify;
    text-autospace: none;
    font-size: 18px;
    margin-top: 10px;
    margin-left: 30px;
    line-height: 21px;
  }

  h1 {
    mso-style-name: "Heading 1\\,Header1\\,1\\,Part\\,Chapter Heading\\,Section Heading\\,PARA1\\,Heading1\\,Lev 1\\,section\\,H1\\,Heading\\,Heading 10\\,Appendix\\,Appendix1\\,Appendix2\\,Appendix3\\,Appendix11\\,Appendix21\\,Appendix4\\,Appendix12\\,Appendix22\\,Appendix5\\,Appendix13\\,Appendix23\\,Appendix6\\,Appendix14";
    mso-style-link: "Heading 1 Char\\,Header1 Char\\,1 Char\\,Part Char\\,Chapter Heading Char\\,Section Heading Char\\,PARA1 Char\\,Heading1 Char\\,Lev 1 Char\\,section Char\\,H1 Char\\,Heading Char\\,Heading 10 Char\\,Appendix Char\\,Appendix1 Char\\,Appendix2 Char\\,Appendix3 Char\\,Appendix11 Char";
    margin-top: 38px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 38px;
    text-align: justify;
    page-break-after: avoid;
    text-autospace: none;
    font-size: 13px;
    text-transform: uppercase;
  }

  h2 {
    mso-style-name: "Heading 2\\,Chapter\\,1\\.Seite\\,Sub Heading\\,2\\,Reset numbering\\,sub-sect\\,h2\\,section header\\,no section\\,21\\,sub-sect1\\,22\\,sub-sect2\\,23\\,sub-sect3\\,24\\,sub-sect4\\,25\\,sub-sect5\\,\\(1\\.1\\,1\\.2\\,1\\.3 etc\\)\\,PARA2\\,h21\\,h22\\,Lev 2\\,KJL\\:1st Level\\,Sub Headings\\,l2\\,level 2\\,H2\\,L2\\,dd heading 2";
    mso-style-link: "Heading 2 Char\\,Chapter Char\\,1\\.Seite Char\\,Sub Heading Char\\,2 Char\\,Reset numbering Char\\,sub-sect Char\\,h2 Char\\,section header Char\\,no section Char\\,21 Char\\,sub-sect1 Char\\,22 Char\\,sub-sect2 Char\\,23 Char\\,sub-sect3 Char\\,24 Char\\,sub-sect4 Char\\,25 Char\\,\\(1\\.1 Char";
    text-align: justify;
    line-height: 21px;
    margin-left: 30px;
    margin-top: 10px;
    text-autospace: none;
    font-size: 18px;
    font-weight: normal;
  }

  h3 {
    mso-style-name: "Heading 3\\,Section\\,Annotationen\\,Side Heading\\,3\\,Level 1 - 1\\,h3\\,h31\\,31\\,h32\\,32\\,h33\\,33\\,h34\\,34\\,h35\\,35\\,sub-sub\\,sub-sub1\\,sub-sub2\\,sub-sub3\\,sub-sub4\\,sub section header\\,PARA3\\,Head 3\\,BOD 1\\,BOD 0\\,Lev 3\\,KJL\\:2nd Level\\,311\\,sub-sub11\\,subsect\\,Überschrift 3\\,Minor\\,PARA31";
    mso-style-link: "Heading 3 Char\\,Section Char\\,Annotationen Char\\,Side Heading Char\\,3 Char\\,Level 1 - 1 Char\\,h3 Char\\,h31 Char\\,31 Char\\,h32 Char\\,32 Char\\,h33 Char\\,33 Char\\,h34 Char\\,34 Char\\,h35 Char\\,35 Char\\,sub-sub Char\\,sub-sub1 Char\\,sub-sub2 Char\\,sub-sub3 Char\\,sub-sub4 Char";
    margin-top: 10px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 75px;
    text-align: justify;
    text-indent: -75px;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
    font-weight: normal;
  }

  h4 {
    mso-style-name: "Heading 4\\,Subsection\\,Level 2 - a\\,PARA4\\,Lev 4\\,Schedules\\,4\\,H4\\,h4\\,14\\,l4\\,141\\,h41\\,l41\\,41\\,142\\,h42\\,l42\\,h43\\,a\\.\\,Map Title\\,42\\,parapoint\\,¶\\,143\\,h44\\,l43\\,43\\,1411\\,h411\\,l411\\,411\\,1421\\,h421\\,l421\\,h431\\,a\\.1\\,Map Title1\\,421\\,parapoint1\\,¶1\\,H41\\,PARA41\\,PARA42\\,PARA43\\,PARA411\\,n\\,Te";
    mso-style-link: "Heading 4 Char\\,Subsection Char\\,Level 2 - a Char\\,PARA4 Char\\,Lev 4 Char\\,Schedules Char\\,4 Char\\,H4 Char\\,h4 Char\\,14 Char\\,l4 Char\\,141 Char\\,h41 Char\\,l41 Char\\,41 Char\\,142 Char\\,h42 Char\\,l42 Char\\,h43 Char\\,a\\. Char\\,Map Title Char\\,42 Char\\,parapoint Char\\,¶ Char";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 112px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
    font-weight: normal;
  }

  h5 {
    mso-style-name: "Heading 5\\,Subheading\\,Level 3 - i\\,Lev 5\\,Response Type\\,Response Type1\\,Response Type2\\,Response Type3\\,Response Type4\\,Response Type5\\,Response Type6\\,Response Type7\\,Appendix A to X\\,Heading 5   Appendix A to X\\,H5\\,h5\\,Heading 5\\(unused\\)\\,Level 3 - \\(i\\)\\,l5\\,ITT t5\\,s\\,\\(";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 150px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
    font-weight: normal;
  }

  h6 {
    mso-style-name: "Heading 6\\,Legal Level 1\\.\\,Lev 6\\,Heading 6  Appendix Y & Z\\,Heading 6\\(unused\\)\\,L1 PIP\\,h6\\,H6\\,H61\\,H62\\,H63\\,H64\\,H65\\,H66\\,H67\\,H68\\,H69\\,H610\\,H611\\,H612\\,H613\\,H614\\,H615\\,H616\\,H617\\,H618\\,H619\\,H621\\,H631\\,H641\\,H651\\,H661\\,H671\\,H681\\,H691\\,H6101\\,H6111\\,H6121\\,H6131\\,H6141\\,H6151\\,\\(A\\)";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 190px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
    font-weight: normal;
  }

  p.MsoHeading7, li.MsoHeading7, div.MsoHeading7 {
    mso-style-name: "Heading 7\\,Legal Level 1\\.1\\.\\,Lev 7\\,Heading 7\\(unused\\)\\,L2 PIP\\,H7DO NOT USE\\,Numbered - 7\\,Lev 71\\,Numbered - 71\\,Lev 72\\,Numbered - 72\\,Lev 73\\,Numbered - 73\\,ITT t7\\,PA Appendix Major\\,level1-noHeading\\,h7";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 226px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.MsoHeading8, li.MsoHeading8, div.MsoHeading8 {
    mso-style-name: "Heading 8\\,Legal Level 1\\.1\\.1\\.\\,Lev 8\\,h8 DO NOT USE\\,Numbered - 8\\,Lev 81\\,Numbered - 81\\,Lev 82\\,Numbered - 82\\,Lev 83\\,Numbered - 83\\,ITT t8\\,PA Appendix Minor\\,level2\\(a\\)\\,h8";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 264px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.MsoHeading9, li.MsoHeading9, div.MsoHeading9 {
    mso-style-name: "Heading 9\\,Legal Level 1\\.1\\.1\\.1\\.\\,Lev 9\\,App1\\,App Heading\\,Heading 9 \\(defunct\\)\\,h9 DO NOT USE\\,Numbered - 9\\,Lev 91\\,Numbered - 91\\,Lev 92\\,Numbered - 92\\,Lev 93\\,Numbered - 93\\,ITT t9\\,h9";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 302px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.MsoIndex1, li.MsoIndex1, div.MsoIndex1 {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 13px;
    text-align: justify;
    text-indent: -13px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.MsoToc1, li.MsoToc1, div.MsoToc1 {
    margin-top: 4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 76px;
    text-align: justify;
    text-indent: -76px;
    text-autospace: none;
    font-size: 13px;
    
    text-transform: uppercase;
  }

  p.MsoToc2, li.MsoToc2, div.MsoToc2 {
    margin-top: 8px;
    margin-right: 76px;
    margin-bottom: 0;
    margin-left: 0;
    text-align: justify;
    text-indent: 94px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.MsoToc3, li.MsoToc3, div.MsoToc3 {
    margin-top: 8px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: justify;
    text-indent: 114px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.MsoToc4, li.MsoToc4, div.MsoToc4 {
    margin-top: 8px;
    margin-right: 76px;
    margin-bottom: 0;
    margin-left: 0;
    text-align: justify;
    text-indent: 132px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.MsoToc5, li.MsoToc5, div.MsoToc5 {
    margin-top: 8px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: justify;
    text-indent: 132px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.MsoToc6, li.MsoToc6, div.MsoToc6 {
    margin-top: 4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: justify;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.MsoToc7, li.MsoToc7, div.MsoToc7 {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 76px;
    text-align: justify;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.MsoToc8, li.MsoToc8, div.MsoToc8 {
    margin-top: 4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: justify;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.MsoToc9, li.MsoToc9, div.MsoToc9 {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 76px;
    text-align: justify;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.MsoFootnoteText, li.MsoFootnoteText, div.MsoFootnoteText {
    margin: 0;
    text-align: justify;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.MsoCommentText, li.MsoCommentText, div.MsoCommentText {
    mso-style-link: "Comment Text Char";
    margin: 0;
    text-align: justify;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.MsoHeader, li.MsoHeader, div.MsoHeader {
    margin: 0;
    text-align: justify;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.MsoFooter, li.MsoFooter, div.MsoFooter {
    mso-style-link: "Footer Char";
    margin: 0;
    text-align: justify;
    text-autospace: none;
    font-size: 8px;
    
  }

  p.MsoEnvelopeAddress, li.MsoEnvelopeAddress, div.MsoEnvelopeAddress {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 2px;
    font-size: 13px;
  }

  p.MsoEnvelopeReturn, li.MsoEnvelopeReturn, div.MsoEnvelopeReturn {
    margin: 0;
    font-size: 13px;
    
  }

  span.MsoFootnoteReference {
    
    vertical-align: super;
  }

  span.MsoEndnoteReference {
    vertical-align: super;
  }

  p.MsoEndnoteText, li.MsoEndnoteText, div.MsoEndnoteText {
    mso-style-link: "Endnote Text Char";
    margin: 0;
    text-align: justify;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.MsoTitle, li.MsoTitle, div.MsoTitle {
    margin: 0;
    text-align: center;
    text-autospace: none;
    font-size: 13px;
    color: red;
    display: none;
    font-weight: bold;
  }

  p.MsoBodyTextIndent, li.MsoBodyTextIndent, div.MsoBodyTextIndent {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 416px;
    text-align: justify;
    text-autospace: none;
    font-size: 10px;
    
  }

  p.MsoSubtitle, li.MsoSubtitle, div.MsoSubtitle {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 4px;
    margin-left: 0;
    text-align: center;
    text-autospace: none;
    font-size: 16px;
    
  }

  a:link, span.MsoHyperlink {
    color: blue;
  }
;

  a:visited, span.MsoHyperlinkFollowed {
    color: purple;
  }

  p.MsoDocumentMap, li.MsoDocumentMap, div.MsoDocumentMap {
    margin: 0;
    text-align: justify;
    background: navy;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.MsoCommentSubject, li.MsoCommentSubject, div.MsoCommentSubject {
    mso-style-link: "Comment Subject Char";
    margin: 0;
    text-align: justify;
    text-autospace: none;
    font-size: 13px;
    
    font-weight: bold;
  }

  p.MsoAcetate, li.MsoAcetate, div.MsoAcetate {
    mso-style-link: "Balloon Text Char";
    margin: 0;
    text-align: justify;
    text-autospace: none;
    font-size: 10px;
   font-family: 'Neue Haas Grotesk Display Pro';
  }

  p.MsoRMPane, li.MsoRMPane, div.MsoRMPane {
    margin: 0;
    font-size: 13px;
    
  }

  p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph {
    margin-top: 10px;
    margin-bottom: 0;
    margin-left: 30px;
    text-align: justify;
    text-autospace: none;
    font-size: 18px;
    
  }

  p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 48px;
    text-align: justify;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 48px;
    text-align: justify;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 48px;
    text-align: justify;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.TextLevel1, li.TextLevel1, div.TextLevel1 {
    mso-style-name: "Text Level 1";
    margin-top: 13px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 38px;
    text-align: justify;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.TextLevel2, li.TextLevel2, div.TextLevel2 {
    mso-style-name: "Text Level 2";
    margin-top: 13px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 56px;
    text-align: justify;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.TextLevel3, li.TextLevel3, div.TextLevel3 {
    mso-style-name: "Text Level 3";
    margin-top: 10px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 76px;
    text-align: justify;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.TextLevel4, li.TextLevel4, div.TextLevel4 {
    mso-style-name: "Text Level 4";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 114px;
    text-align: justify;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.TextLevel5, li.TextLevel5, div.TextLevel5 {
    mso-style-name: "Text Level 5";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 152px;
    text-align: justify;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.TextLevel6, li.TextLevel6, div.TextLevel6 {
    mso-style-name: "Text Level 6";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 190px;
    text-align: justify;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.TextLevel7, li.TextLevel7, div.TextLevel7 {
    mso-style-name: "Text Level 7";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 226px;
    text-align: justify;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.TextLevel8, li.TextLevel8, div.TextLevel8 {
    mso-style-name: "Text Level 8";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 265px;
    text-align: justify;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.TextLevel9, li.TextLevel9, div.TextLevel9 {
    mso-style-name: "Text Level 9";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 302px;
    text-align: justify;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  span.BoldCaps {
    mso-style-name: "Bold Caps";
    color: windowtext;
    text-transform: uppercase;
    font-weight: bold;
    vertical-align: baseline;
  }

  span.Caps {
    mso-style-name: Caps;
    color: windowtext;
    text-transform: uppercase;
    vertical-align: baseline;
  }

  p.Definition, li.Definition, div.Definition {
    mso-style-name: Definition;
    margin-top: 4.2px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 38px;
    text-align: justify;
    line-height: 18.62px;
    page-break-after: avoid;
    text-autospace: none;
    font-size: 13px;
    
    font-weight: bold;
  }

  p.DefinitionText, li.DefinitionText, div.DefinitionText {
    mso-style-name: "Definition Text";
    margin-top: 4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 114px;
    text-align: justify;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.DefinitionSubClause, li.DefinitionSubClause, div.DefinitionSubClause {
    mso-style-name: "Definition Sub Clause";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 152px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.DefinitionSubSubClause, li.DefinitionSubSubClause, div.DefinitionSubSubClause {
    mso-style-name: "Definition SubSub Clause";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 190px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.DocumentSubhead, li.DocumentSubhead, div.DocumentSubhead {
    mso-style-name: "Document Subhead";
    margin-top: 38px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: justify;
    page-break-after: avoid;
    text-autospace: none;
    font-size: 13px;
    
    text-transform: uppercase;
    font-weight: bold;
  }

  p.DocumentTitle, li.DocumentTitle, div.DocumentTitle {
    mso-style-name: "Document Title";
    margin-top: 38px;
    margin-right: 0;
    margin-bottom: 32px;
    margin-left: 0;
    text-align: center;
    page-break-after: avoid;
    text-autospace: none;
    font-size: 20px;
    
    text-transform: uppercase;
    font-weight: bold;
  }

  p.Parties, li.Parties, div.Parties {
    mso-style-name: Parties;
    margin-top: 13px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 38px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.Recitals, li.Recitals, div.Recitals {
    mso-style-name: Recitals;
    margin-top: 13px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 38px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.StandardText, li.StandardText, div.StandardText {
    mso-style-name: "Standard Text";
    margin-top: 13px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: justify;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.Standardlevel1, li.Standardlevel1, div.Standardlevel1 {
    mso-style-name: "Standard level 1";
    margin-top: 13px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 46px;
    text-align: justify;
    text-indent: -46px;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.StandardHead, li.StandardHead, div.StandardHead {
    mso-style-name: "Standard Head";
    margin-top: 16px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: center;
    text-autospace: none;
    font-size: 13px;
    
    text-transform: uppercase;
    font-weight: bold;
  }

  p.StandardSubhead, li.StandardSubhead, div.StandardSubhead {
    mso-style-name: "Standard Subhead";
    margin-top: 16px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: center;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.Standardlevel2, li.Standardlevel2, div.Standardlevel2 {
    mso-style-name: "Standard level 2";
    margin-top: 13px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 90.6px;
    text-align: justify;
    text-indent: -46px;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.Standardlevel3, li.Standardlevel3, div.Standardlevel3 {
    mso-style-name: "Standard level 3";
    margin-top: 10px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 136px;
    text-align: justify;
    text-indent: -46px;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.Standardlevel4, li.Standardlevel4, div.Standardlevel4 {
    mso-style-name: "Standard level 4";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 182px;
    text-align: justify;
    text-indent: -46px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.Standardlevel5, li.Standardlevel5, div.Standardlevel5 {
    mso-style-name: "Standard level 5";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 226px;
    text-align: justify;
    text-indent: -46px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.EmbeddedNotes, li.EmbeddedNotes, div.EmbeddedNotes {
    mso-style-name: "Embedded Notes";
    margin-top: 13px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: justify;
    line-height: 125%;
    text-autospace: none;
    font-size: 13px;
    
    color: blue;
    text-transform: uppercase;
  }

  p.Standardlevel6, li.Standardlevel6, div.Standardlevel6 {
    mso-style-name: "Standard level 6";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 226px;
    text-align: justify;
    text-indent: -46px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.Standardlevel7, li.Standardlevel7, div.Standardlevel7 {
    mso-style-name: "Standard level 7";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 226px;
    text-align: justify;
    text-indent: -46px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.Standardlevel8, li.Standardlevel8, div.Standardlevel8 {
    mso-style-name: "Standard level 8";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 226px;
    text-align: justify;
    text-indent: -46px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.Standardlevel9, li.Standardlevel9, div.Standardlevel9 {
    mso-style-name: "Standard level 9";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 226px;
    text-align: justify;
    text-indent: -46px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.Style1, li.Style1, div.Style1 {
    mso-style-name: Style1;
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 152px;
    text-align: justify;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.Body1, li.Body1, div.Body1 {
    mso-style-name: "Body 1";
    margin: 0;
    line-height: 16.6px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.Body2, li.Body2, div.Body2 {
    mso-style-name: "Body 2";
    margin: 0;
    text-indent: 7.6px;
    line-height: 16px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.Boxbulletheadline, li.Boxbulletheadline, div.Boxbulletheadline {
    mso-style-name: "Box bullet headline";
    margin: 0;
    text-align: justify;
    line-height: 16px;
    text-autospace: none;
    border: none;
    padding: 0;
    font-size: 13px;
    
    color: black;
    font-weight: bold;
  }

  p.Bullet1, li.Bullet1, div.Bullet1 {
    mso-style-name: Bullet1;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 38px;
    text-indent: -38px;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.Bullet2, li.Bullet2, div.Bullet2 {
    mso-style-name: Bullet2;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 76px;
    text-indent: -38px;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.Headline, li.Headline, div.Headline {
    mso-style-name: Headline;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 64px;
    margin-left: 0;
    text-autospace: none;
    font-size: 24px;
    
  }

  p.Subhead, li.Subhead, div.Subhead {
    mso-style-name: Subhead;
    margin: 0;
    line-height: 16.6px;
    page-break-after: avoid;
    text-autospace: none;
    font-size: 13px;
    
    color: #981E32;
    text-transform: uppercase;
    font-weight: bold;
  }

  p.Subhead1, li.Subhead1, div.Subhead1 {
    mso-style-name: "Subhead 1";
    margin: 0;
    line-height: 16.6px;
    page-break-after: avoid;
    text-autospace: none;
    font-size: 13px;
    
    font-weight: bold;
  }

  p.Subhead2, li.Subhead2, div.Subhead2 {
    mso-style-name: "Subhead 2";
    margin: 0;
    line-height: 16.6px;
    page-break-after: avoid;
    text-autospace: none;
    font-size: 13px;
  }

  p.DefinitionSubSubSubClause, li.DefinitionSubSubSubClause, div.DefinitionSubSubSubClause {
    mso-style-name: "Definition SubSubSub Clause";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 226px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  span.Document7 {
    mso-style-name: "Document 7";
  }

  span.Document8 {
    mso-style-name: "Document 8";
  }

  span.LogoForHiding {
    mso-style-name: LogoForHiding;
    
  }

  p.Recitalssub, li.Recitalssub, div.Recitalssub {
    mso-style-name: "Recitals sub";
    margin-top: 10px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 76px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.ScheduleStyle1, li.ScheduleStyle1, div.ScheduleStyle1 {
    mso-style-name: "Schedule Style 1";
    margin-top: 38px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 38px;
    text-align: justify;
    text-indent: -38px;
    page-break-after: avoid;

    text-autospace: none;
    font-size: 13px;
    
    text-transform: uppercase;
    font-weight: bold;
  }

  p.ScheduleStyle2, li.ScheduleStyle2, div.ScheduleStyle2 {
    mso-style-name: "Schedule Style 2";
    margin-top: 13px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 56px;
    text-align: justify;
    text-indent: -56px;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.ScheduleStyle3, li.ScheduleStyle3, div.ScheduleStyle3 {
    mso-style-name: "Schedule Style 3";
    margin-top: 10px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 76px;
    text-align: justify;
    text-indent: -76px;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.ScheduleStyle4, li.ScheduleStyle4, div.ScheduleStyle4 {
    mso-style-name: "Schedule Style 4";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 114px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.ScheduleStyle5, li.ScheduleStyle5, div.ScheduleStyle5 {
    mso-style-name: "Schedule Style 5";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 152px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.ScheduleStyle6, li.ScheduleStyle6, div.ScheduleStyle6 {
    mso-style-name: "Schedule Style 6";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 190px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.ScheduleStyle7, li.ScheduleStyle7, div.ScheduleStyle7 {
    mso-style-name: "Schedule Style 7";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 226px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.ScheduleStyle8, li.ScheduleStyle8, div.ScheduleStyle8 {
    mso-style-name: "Schedule Style 8";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 265px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.ScheduleStyle9, li.ScheduleStyle9, div.ScheduleStyle9 {
    mso-style-name: "Schedule Style 9";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 302px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.ScheduleStyleHead, li.ScheduleStyleHead, div.ScheduleStyleHead {
    mso-style-name: "Schedule Style Head";
    margin-top: 16px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: center;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
    text-transform: uppercase;
    font-weight: bold;
  }

  p.ScheduleStyleSubhead, li.ScheduleStyleSubhead, div.ScheduleStyleSubhead {
    mso-style-name: "Schedule Style Subhead";
    margin-top: 16px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: center;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.ScheduleStyleTitle, li.ScheduleStyleTitle, div.ScheduleStyleTitle {
    mso-style-name: "Schedule Style Title";
    margin-top: 13px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: justify;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.ScheduleHead, li.ScheduleHead, div.ScheduleHead {
    mso-style-name: "Schedule Head";
    margin-top: 16px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: center;
    text-indent: 0;
    line-height: 18.62px;
    page-break-after: avoid;

    text-autospace: none;
    font-size: 13px;
    
    font-weight: bold;
  }

  p.StandardNumbering, li.StandardNumbering, div.StandardNumbering {
    mso-style-name: "Standard Numbering";
    margin-top: 13px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 114px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.ScheduleSubhead, li.ScheduleSubhead, div.ScheduleSubhead {
    mso-style-name: "Schedule Subhead";
    margin-top: 16px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: center;
    line-height: 18.62px;
    page-break-after: avoid;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.AnnexureHead, li.AnnexureHead, div.AnnexureHead {
    mso-style-name: "Annexure Head";
    margin-top: 16px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: center;
    text-indent: 0;
    line-height: 18.62px;
    page-break-after: avoid;

    text-autospace: none;
    font-size: 13px;
    
    font-weight: bold;
  }

  p.AnnexurePartHead, li.AnnexurePartHead, div.AnnexurePartHead {
    mso-style-name: "Annexure Part Head";
    margin-top: 16px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: center;
    text-indent: 0;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.AnnexureSubPartHead, li.AnnexureSubPartHead, div.AnnexureSubPartHead {
    mso-style-name: "Annexure SubPart Head";
    margin-top: 16px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: center;
    text-indent: 0;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.AppendixHead, li.AppendixHead, div.AppendixHead {
    mso-style-name: "Appendix Head";
    margin-top: 16px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: center;
    text-indent: 0;
    line-height: 18.62px;
    page-break-after: avoid;

    text-autospace: none;
    font-size: 13px;
    
    font-weight: bold;
  }

  p.ScheduleParthead, li.ScheduleParthead, div.ScheduleParthead {
    mso-style-name: "Schedule Part head";
    margin-top: 16px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: center;
    text-indent: 0;
    line-height: 18.62px;
    page-break-after: avoid;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.AppendixParthead, li.AppendixParthead, div.AppendixParthead {
    mso-style-name: "Appendix Part head";
    margin-top: 16px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: center;
    text-indent: 0;
    line-height: 18.62px;
    page-break-after: avoid;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.AppendixSubParthead, li.AppendixSubParthead, div.AppendixSubParthead {
    mso-style-name: "Appendix SubPart head";
    margin-top: 16px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: center;
    text-indent: 0;
    line-height: 18.62px;
    page-break-after: avoid;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.DefinitionSubSubSubSubClause, li.DefinitionSubSubSubSubClause, div.DefinitionSubSubSubSubClause {
    mso-style-name: "Definition SubSubSubSub Clause";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 265px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.GeneralHeading, li.GeneralHeading, div.GeneralHeading {
    mso-style-name: "General Heading";
    margin-top: 16px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: center;
    line-height: 18.62px;
    page-break-after: avoid;

    text-autospace: none;
    font-size: 13px;
    font-family: 'Neue Haas Grotesk Display Pro';
    text-transform: uppercase;
    font-weight: bold;
  }

  p.PlainNumbering1, li.PlainNumbering1, div.PlainNumbering1 {
    mso-style-name: "Plain Numbering 1";
    margin-top: 13px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 38px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.PlainNumbering2, li.PlainNumbering2, div.PlainNumbering2 {
    mso-style-name: "Plain Numbering 2";
    margin-top: 13px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 30px;
    text-align: justify;
    line-height: 18.62px;
    text-autospace: none;
    font-size: 13px;
    
  }

  p.PlainNumbering3, li.PlainNumbering3, div.PlainNumbering3 {
    mso-style-name: "Plain Numbering 3";
    margin-top: 10px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 76px;
    text-align: justify;
    text-indent: -76px;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.PlainNumbering4, li.PlainNumbering4, div.PlainNumbering4 {
    mso-style-name: "Plain Numbering 4";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 114px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.PlainNumbering5, li.PlainNumbering5, div.PlainNumbering5 {
    mso-style-name: "Plain Numbering 5";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 152px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.PlainNumbering6, li.PlainNumbering6, div.PlainNumbering6 {
    mso-style-name: "Plain Numbering 6";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 190px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.PlainNumbering7, li.PlainNumbering7, div.PlainNumbering7 {
    mso-style-name: "Plain Numbering 7";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 226px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.PlainNumbering8, li.PlainNumbering8, div.PlainNumbering8 {
    mso-style-name: "Plain Numbering 8";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 265px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.PlainNumbering9, li.PlainNumbering9, div.PlainNumbering9 {
    mso-style-name: "Plain Numbering 9";
    margin-top: 5.4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 302px;
    text-align: justify;
    text-indent: -38px;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.ScheduleSubParthead, li.ScheduleSubParthead, div.ScheduleSubParthead {
    mso-style-name: "Schedule SubPart head";
    margin-top: 16px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: center;
    text-indent: 0;
    line-height: 18.62px;
    page-break-after: avoid;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.SectionHead, li.SectionHead, div.SectionHead {
    mso-style-name: "Section Head";
    margin-top: 16px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: center;
    text-indent: 0;
    line-height: 18.62px;
    page-break-after: avoid;

    text-autospace: none;
    font-size: 13px;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-weight: bold;
  }

  p.SectionPartHead, li.SectionPartHead, div.SectionPartHead {
    mso-style-name: "Section Part Head";
    margin-top: 16px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: center;
    text-indent: 0;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  p.SectionSubHead, li.SectionSubHead, div.SectionSubHead {
    mso-style-name: "Section Sub Head";
    margin-top: 16px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: center;
    text-indent: 0;
    line-height: 18.62px;

    text-autospace: none;
    font-size: 13px;
    
  }

  span.EndnoteTextChar {
    mso-style-name: "Endnote Text Char";
    mso-style-link: "Endnote Text";
    
  }

  span.Heading2Char {
    mso-style-name: "Heading 2 Char\\,Chapter Char\\,1\\.Seite Char\\,Sub Heading Char\\,2 Char\\,Reset numbering Char\\,sub-sect Char\\,h2 Char\\,section header Char\\,no section Char\\,21 Char\\,sub-sect1 Char\\,22 Char\\,sub-sect2 Char\\,23 Char\\,sub-sect3 Char\\,24 Char\\,sub-sect4 Char\\,25 Char\\,\\(1\\.1 Char";
    mso-style-link: "Heading 2\\,Chapter\\,1\\.Seite\\,Sub Heading\\,2\\,Reset numbering\\,sub-sect\\,h2\\,section header\\,no section\\,21\\,sub-sect1\\,22\\,sub-sect2\\,23\\,sub-sect3\\,24\\,sub-sect4\\,25\\,sub-sect5\\,\\(1\\.1\\,1\\.2\\,1\\.3 etc\\)\\,PARA2\\,h21\\,h22\\,Lev 2\\,KJL\\:1st Level\\,Sub Headings\\,l2\\,level 2\\,H2\\,L2\\,dd heading 2";
    
  }

  p.ClientMain4, li.ClientMain4, div.ClientMain4 {
    mso-style-name: ClientMain_4;
    mso-style-link: "ClientMain_4 Char";
    margin-top: 10px;
    margin-left: 30px;
    line-height: 21px;
    text-autospace: none;
    font-size: 18px;
  }

  span.ClientMain4Char {
    mso-style-name: "ClientMain_4 Char";
    mso-style-link: ClientMain_4;
    
  }

  p.ClientMain3, li.ClientMain3, div.ClientMain3 {
    mso-style-name: ClientMain_3;
    mso-style-link: "ClientMain_3 Char";
    font-family: 'Neue Haas Grotesk Display Pro';
    margin-top: 10px;
    margin-left: 30px;
    text-align: justify;
    line-height: 21px;
    text-autospace: none;
    font-size: 18px;
  }

  span.ClientMain3Char {
    mso-style-name: "ClientMain_3 Char";
    mso-style-link: ClientMain_3;
  }

  p.ClientMain2, li.ClientMain2, div.ClientMain2 {
    mso-style-name: ClientMain_2;
    mso-style-link: "ClientMain_2 Char";
    font-family: 'Neue Haas Grotesk Display Pro';
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
    line-height: 21px;
    color: #0E1424;
    margin-top:10px;
    margin-left: 30px;
    text-align: justify;
    text-autospace: none;
  }

  p.ClientMain2 span{
    margin-right: 5px;
  }

  span.ClientMain2Char {
    mso-style-name: "ClientMain_2 Char";
    mso-style-link: ClientMain_2;
    
  }

  p.ClientMain1, li.ClientMain1, div.ClientMain1 {
    mso-style-name: ClientMain_1;
    mso-style-link: "ClientMain_1 Char";
    margin: 10px 0;
    text-align: justify;
    line-height: 24px;
    text-autospace: none;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
  }
  p.ClientMain1 span {
    margin-right: 21px;
  }

  span.ClientMain1Char {
    mso-style-name: "ClientMain_1 Char";
    mso-style-link: ClientMain_1;
    
    text-transform: uppercase;
    font-weight: bold;
  }

  span.Heading3Char {
    mso-style-name: "Heading 3 Char\\,Section Char\\,Annotationen Char\\,Side Heading Char\\,3 Char\\,Level 1 - 1 Char\\,h3 Char\\,h31 Char\\,31 Char\\,h32 Char\\,32 Char\\,h33 Char\\,33 Char\\,h34 Char\\,34 Char\\,h35 Char\\,35 Char\\,sub-sub Char\\,sub-sub1 Char\\,sub-sub2 Char\\,sub-sub3 Char\\,sub-sub4 Char";
    mso-style-link: "Heading 3\\,Section\\,Annotationen\\,Side Heading\\,3\\,Level 1 - 1\\,h3\\,h31\\,31\\,h32\\,32\\,h33\\,33\\,h34\\,34\\,h35\\,35\\,sub-sub\\,sub-sub1\\,sub-sub2\\,sub-sub3\\,sub-sub4\\,sub section header\\,PARA3\\,Head 3\\,BOD 1\\,BOD 0\\,Lev 3\\,KJL\\:2nd Level\\,311\\,sub-sub11\\,subsect\\,Überschrift 3\\,Minor\\,PARA31";
    
  }

  span.Heading4Char {
    mso-style-name: "Heading 4 Char\\,Subsection Char\\,Level 2 - a Char\\,PARA4 Char\\,Lev 4 Char\\,Schedules Char\\,4 Char\\,H4 Char\\,h4 Char\\,14 Char\\,l4 Char\\,141 Char\\,h41 Char\\,l41 Char\\,41 Char\\,142 Char\\,h42 Char\\,l42 Char\\,h43 Char\\,a\\. Char\\,Map Title Char\\,42 Char\\,parapoint Char\\,¶ Char";
    mso-style-link: "Heading 4\\,Subsection\\,Level 2 - a\\,PARA4\\,Lev 4\\,Schedules\\,4\\,H4\\,h4\\,14\\,l4\\,141\\,h41\\,l41\\,41\\,142\\,h42\\,l42\\,h43\\,a\\.\\,Map Title\\,42\\,parapoint\\,¶\\,143\\,h44\\,l43\\,43\\,1411\\,h411\\,l411\\,411\\,1421\\,h421\\,l421\\,h431\\,a\\.1\\,Map Title1\\,421\\,parapoint1\\,¶1\\,H41\\,PARA41\\,PARA42\\,PARA43\\,PARA411\\,n\\,Te";
    
  }

  span.Heading1Char {
    mso-style-name: "Heading 1 Char\\,Header1 Char\\,1 Char\\,Part Char\\,Chapter Heading Char\\,Section Heading Char\\,PARA1 Char\\,Heading1 Char\\,Lev 1 Char\\,section Char\\,H1 Char\\,Heading Char\\,Heading 10 Char\\,Appendix Char\\,Appendix1 Char\\,Appendix2 Char\\,Appendix3 Char\\,Appendix11 Char";
    mso-style-link: "Heading 1\\,Header1\\,1\\,Part\\,Chapter Heading\\,Section Heading\\,PARA1\\,Heading1\\,Lev 1\\,section\\,H1\\,Heading\\,Heading 10\\,Appendix\\,Appendix1\\,Appendix2\\,Appendix3\\,Appendix11\\,Appendix21\\,Appendix4\\,Appendix12\\,Appendix22\\,Appendix5\\,Appendix13\\,Appendix23\\,Appendix6\\,Appendix14";
    
    text-transform: uppercase;
    font-weight: bold;
  }

  p.Sch1styleclause, li.Sch1styleclause, div.Sch1styleclause {
    mso-style-name: "Sch  \\(1style\\) clause";
    margin-top: 24px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 48px;
    text-align: justify;
    text-indent: -48px;
    line-height: 4.2px;
    font-size: 14.5px;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-variant: small-caps;
    font-weight: bold;
  }

  p.Sch1stylesubclause, li.Sch1stylesubclause, div.Sch1stylesubclause {
    mso-style-name: "Sch  \\(1style\\) sub clause";
    margin-top: 18.62px;
    margin-right: 0;
    margin-bottom: 8px;
    margin-left: 48px;
    text-align: justify;
    text-indent: -48px;
    line-height: 4.2px;
    font-size: 14.5px;
    font-family: 'Neue Haas Grotesk Display Pro';
    color: black;
  }

  p.Sch1stylepara, li.Sch1stylepara, div.Sch1stylepara {
    mso-style-name: "Sch \\(1style\\) para";
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 8px;
    margin-left: 104px;
    text-align: justify;
    text-indent: -38px;
    line-height: 4.2px;
    font-size: 14.5px;
    font-family: 'Neue Haas Grotesk Display Pro';
  }

  p.Sch1stylesubpara, li.Sch1stylesubpara, div.Sch1stylesubpara {
    mso-style-name: "Sch \\(1style\\) sub para";
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 8px;
    margin-left: 152px;
    text-align: justify;
    text-indent: -38px;
    line-height: 4.2px;
    font-size: 14.5px;
    font-family: 'Neue Haas Grotesk Display Pro';
  }

  span.BalloonTextChar {
    mso-style-name: "Balloon Text Char";
    mso-style-link: "Balloon Text";
   font-family: 'Neue Haas Grotesk Display Pro';
  }

  span.FooterChar {
    mso-style-name: "Footer Char";
    mso-style-link: Footer;
    
  }

  span.CommentTextChar {
    mso-style-name: "Comment Text Char";
    mso-style-link: "Comment Text";
    
  }

  span.CommentSubjectChar {
    mso-style-name: "Comment Subject Char";
    mso-style-link: "Comment Subject";
    
    font-weight: bold;
  }

  p.CMSANDefinitions1, li.CMSANDefinitions1, div.CMSANDefinitions1 {
    mso-style-name: "CMS AN Definitions 1";
    margin-top: 8px;
    margin-right: 0;
    margin-bottom: 8px;
    margin-left: 56px;
    text-align: justify;
    text-indent: 0;
    line-height: 4.2px;
    font-size: 14.5px;
    font-family: 'Neue Haas Grotesk Display Pro';
    color: black;
  }

  p.CMSANDefinitions2, li.CMSANDefinitions2, div.CMSANDefinitions2 {
    mso-style-name: "CMS AN Definitions 2";
    margin-top: 8px;
    margin-right: 0;
    margin-bottom: 8px;
    margin-left: 114px;
    text-align: justify;
    line-height: 4.2px;
    font-size: 14.5px;
    font-family: 'Neue Haas Grotesk Display Pro';
    color: black;
  }

  p.CMSANDefinitions3, li.CMSANDefinitions3, div.CMSANDefinitions3 {
    mso-style-name: "CMS AN Definitions 3";
    margin-top: 8px;
    margin-right: 0;
    margin-bottom: 8px;
    margin-left: 170px;
    text-align: justify;
    text-indent: -56px;
    line-height: 4.2px;
    font-size: 14.5px;
    font-family: 'Neue Haas Grotesk Display Pro';
    color: black;
  }

  p.CMSANHeading1, li.CMSANHeading1, div.CMSANHeading1 {
    mso-style-name: "CMS AN Heading 1";
    margin-top: 16px;
    margin-right: 0;
    margin-bottom: 8px;
    margin-left: 56px;
    text-align: justify;
    text-indent: -56px;
    line-height: 4.2px;
    page-break-after: avoid;
    font-size: 14.5px;
    font-family: 'Neue Haas Grotesk Display Pro';
    color: black;
    text-transform: uppercase;
    font-weight: bold;
  }

  p.CMSANHeading2, li.CMSANHeading2, div.CMSANHeading2 {
    mso-style-name: "CMS AN Heading 2";
    margin-top: 8px;
    margin-right: 0;
    margin-bottom: 8px;
    margin-left: 56px;
    text-align: justify;
    text-indent: -56px;
    line-height: 4.2px;
    font-size: 14.5px;
    font-family: 'Neue Haas Grotesk Display Pro';
    color: black;
  }

  p.CMSANHeading3, li.CMSANHeading3, div.CMSANHeading3 {
    mso-style-name: "CMS AN Heading 3";
    margin-top: 8px;
    margin-right: 0;
    margin-bottom: 8px;
    margin-left: 114px;
    text-align: justify;
    line-height: 4.2px;
    font-size: 14.5px;
    font-family: 'Neue Haas Grotesk Display Pro';
    color: black;
  }

  p.CMSANHeading4, li.CMSANHeading4, div.CMSANHeading4 {
    mso-style-name: "CMS AN Heading 4";
    margin-top: 8px;
    margin-right: 0;
    margin-bottom: 8px;
    margin-left: 170px;
    text-align: justify;
    text-indent: -56px;
    line-height: 4.2px;
    font-size: 14.5px;
    font-family: 'Neue Haas Grotesk Display Pro';
    color: black;
  }

  p.CMSANHeading5, li.CMSANHeading5, div.CMSANHeading5 {
    mso-style-name: "CMS AN Heading 5";
    margin-top: 8px;
    margin-right: 0;
    margin-bottom: 8px;
    margin-left: 226px;
    text-align: justify;
    line-height: 4.2px;
    font-size: 14.5px;
    font-family: 'Neue Haas Grotesk Display Pro';
    color: black;
  }

  p.CMSANHeading6, li.CMSANHeading6, div.CMSANHeading6 {
    mso-style-name: "CMS AN Heading 6";
    margin-top: 8px;
    margin-right: 0;
    margin-bottom: 8px;
    margin-left: 284px;
    text-align: justify;
    text-indent: -56px;
    line-height: 4.2px;
    font-size: 14.5px;
    font-family: 'Neue Haas Grotesk Display Pro';
    color: black;
  }

  p.CMSANMainHeading, li.CMSANMainHeading, div.CMSANMainHeading {
    mso-style-name: "CMS AN Main Heading";
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 16px;
    margin-left: 0;
    text-align: center;
    text-indent: 0;
    line-height: 4.2px;
    page-break-before: always;
    font-size: 14.5px;
    font-family: 'Neue Haas Grotesk Display Pro';
    color: black;
    text-transform: uppercase;
    font-weight: bold;
  }

  p.Pol-Bod, li.Pol-Bod, div.Pol-Bod {
    mso-style-name: Pol-Bod;
    mso-style-link: "Pol-Bod Char";
    margin-top: 4px;
    margin-right: 0;
    margin-bottom: 8px;
    margin-left: 0;
    font-size: 16px;
    font-family: 'Neue Haas Grotesk Display Pro';
    color: black;
  }

  span.Pol-BodChar {
    mso-style-name: "Pol-Bod Char";
    mso-style-link: Pol-Bod;
    font-family: 'Neue Haas Grotesk Display Pro';
    color: black;
  }

  span.Headerorfooter2 {
    mso-style-name: "Header or footer|2_";
    mso-style-link: "Header or footer|2";
    background: white;
  }

  span.Bodytext1 {
    mso-style-name: "Body text|1_";
    mso-style-link: "Body text|1";
    
    background: white;
  }

  span.Heading21 {
    mso-style-name: "Heading \\#2|1_";
    mso-style-link: "Heading \\#2|1";
    
    color: #0F53B1;
    background: white;
    font-weight: bold;
  }

  p.Headerorfooter20, li.Headerorfooter20, div.Headerorfooter20 {
    mso-style-name: "Header or footer|2";
    mso-style-link: "Header or footer|2_";
    margin: 0;
    background: white;
    font-size: 13px;
    font-family: 'Neue Haas Grotesk Display Pro';
  }

  p.Bodytext10, li.Bodytext10, div.Bodytext10 {
    mso-style-name: "Body text|1";
    mso-style-link: "Body text|1_";
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 16px;
    margin-left: 0;
    line-height: 142%;
    background: white;
    font-size: 10px;
    
  }

  p.Heading210, li.Heading210, div.Heading210 {
    mso-style-name: "Heading \\#2|1";
    mso-style-link: "Heading \\#2|1_";
    margin: 0;
    background: white;
    font-size: 14.5px;
    
    color: #0F53B1;
    font-weight: bold;
  }

  .MsoChpDefault {
    font-size: 13px;
  }

  /* Page Definitions */
  @page WordSection1 {
    size: 794px 1124px;
    margin: 94px;
  }

  div.WordSection1 {
    page: WordSection1;
  }

  /* List Definitions */

  ol {
    margin-bottom: 0;
  }

  ul {
    margin-bottom: 0;
  }

  p, span, li, ul, a, div, h1, h2, h3, h4, h5 {
    font-family: 'Neue Haas Grotesk Display Pro';
  }
`

const TemplateComponent = () => (
  <Styled className="WordSection1">
    <p className="ClientMain1" style={{ pageBreakAfter: 'avoid' }}>
      <span lang="EN-GB" style={{ color: 'black', textTransform: 'none', fontWeight: 'normal' }}>
        1.
      </span>
      <span lang="EN-GB">These Terms and conditions</span>
    </p>
    <p className="ClientMain2">
      <a name="_Ref447789502">
        <span lang="EN-GB">
          1.1.
        </span>
        <span lang="EN-GB">
          These are the terms and conditions on which Plend
          Limited
          <span style={{ color: '#0432FF' }}></span>
          (
          <b>"Plend"</b>
          ), enable
          you to borrow money using our lending platform, (the
          <b>"Platform"</b>
          );
          together with:
        </span>
      </a>
    </p>
    <p className="ClientMain3">
      (a) our Website Terms and Conditions <a href='https://plend.co.uk/terms-and-conditions/'>https://plend.co.uk/terms-and-conditions/</a> ;
    </p>
    <p className="ClientMain3">
      (b) our Privacy Policy; and
    </p>
    <p className="ClientMain3">
      (c)

      the Annexes to these terms and conditions,
    </p>
    <p className="ClientMain3">
      they comprise the
      {' '}
      <b>Agreement </b>
      between you and us in respect of your use of the Platform.
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        1.2.
      </span>
      <span lang="EN-GB">
        Plend is a limited company registered in England
        and Wales with company number 12581855. Plend’s registered office address is Aldgate
        Tower, 6
        <sup>th</sup>
        {' '}
        Floor, 2 Leman Street, London, E1 8FA. Plend is
        authorised and regulated by the Financial Conduct Authority (“
        <b>FCA</b>
        ”) with
        registration number FRN 963328.
      </span>
    </p>
    <p className="ClientMain2">
      <a name="_Hlk100762857">
        <span lang="EN-GB">
          1.3.
        </span>
        <span lang="EN-GB">
          To use the Platform, you will also be required
          to enter into the agreements set out in the
        </span>
        {' '}
        Annexes to these terms and
        conditions which contain
      </a>
      <span lang="EN-GB">
        {' '}
        separate agreements with the
        following persons to provide you with the services referenced herein:
      </span>
    </p>
    <p className="ClientMain3">
      (a)
      GoCardless Ltd. (“
      <b>GoCardless</b>
      ”) in relation to processing your
      direct debits as provided in Annex 1 (“
      <b>Payment Processor Agreement</b>
      ”);
      {' '}
    </p>
    <p className="ClientMain3">
      (b)
      Plaid Financial Ltd. (“
      <b>Plaid</b>
      ”) in relation to providing you with
      account information services required for open banking as explained in clause 3
      and provided in Annex 2 (“
      <b>Open Banking Agreement</b>
      ”); and
    </p>
    <p className="ClientMain3">
      (c)
      Modulr Financial Limited (“
      <b>Modulr</b>
      ”) under which Modulr will issue
      and hold electronic money for you in an
      {' '}
      <b>E-Money Account</b>
      {' '}
      as provided in
      Annex 3 (“
      <b>E-Money Account Agreement</b>
      ”).
      {' '}
    </p>
    <p className="ClientMain2">
      <a name="_Hlk100823868">
        1.4.
        The provision of any services through the Platform is conditional upon
        your prior acceptance of each of the Payment Processor Agreement, Open Banking
        Agreement, and E-Money Account Agreement. When you accept this Agreement, you
        will also be accepting the agreements in the Annexes. Subject to any
        requirements under applicable law, in the event of any conflict between these
        terms and conditions (excluding the Annexes), the Payment Processor Agreement,
        the Open Banking Agreement and the E-Money Account Agreement, these terms and
        conditions shall prevail.
        {' '}
      </a>
    </p>
    <p className="ClientMain2">
      <a name="_Hlk100757635">
        <span lang="EN-GB">
          1.5.
        </span>
        <span lang="EN-GB">
          It is important that you read each of the Payment
          Processor Agreement at Annex 1, the Open Banking Agreement at Annex 2, and the
          E-Money Account Agreement at Annex 3, as they are each legally binding on you
          and create direct contractual rights and obligations between you and each of
          GoCardless, Plaid and Modulr, respectively, which are additional to the
          contractual rights and obligations in these terms and conditions. Please note
          that the Payment Processor Agreement, Open Banking Agreement and the E-Money
          Account Agreement may be amended by GoCardless, Plaid, and Modulr,
          respectively, from time to time. We will provide you with an updated Payment
          Processor Agreement, Open Banking Agreement and/or E-Money Account Agreement
          when we are in receipt of the same.
        </span>
      </a>
    </p>
    <p className="ClientMain2">
      <a name="_Ref447789503">
        1.6.
        The Agreement (including the Annexes) will come into effect upon you
        confirming electronically on our website that you agree to its terms and us
        notifying you that your application to use the Platform has been approved.
      </a>
      {' '}
      The
      Agreement will be legally binding when you accept its terms. You should ensure
      that you read the Agreement carefully as it is important and will govern the
      basis on which each party will provide services to you.
    </p>
    <p className="ClientMain2">
      <a name="_Hlk100757670">
        1.7.
        By entering into this Agreement, you agree that:
        {' '}
      </a>
    </p>
    <p className="ClientMain3">
      <a name="_Hlk100762871">
        (a)
        we are authorised to enter into an agreement with GoCardless, acting as
        your agent, for GoCardless to process your direct debit payments to and from
        us, and you accept and are bound by the Payment Processor Agreement;
        {' '}
      </a>
    </p>
    <p className="ClientMain3">
      (b)
      we are authorised to give instructions (as provided for in the Payment
      Processor Agreement and in this Agreement) and provide information concerning
      you to GoCardless, and GoCardless is entitled to rely on any such instructions
      or information without further enquiry;
    </p>
    <p className="ClientMain3">
      (c)
      we are authorised to enter into an agreement with Plaid, acting as your
      agent, for Plaid to provide you with account information services, and you
      accept and are bound by the Open Banking Agreement;
      {' '}
    </p>
    <p className="ClientMain3">
      (d)
      we are authorised to give instructions (as provided for in the Open
      Banking Agreement and in this Agreement) and provide information concerning you
      to Plaid, and Plaid is entitled to rely on any such instructions or information
      without further enquiry;
      {' '}
    </p>
    <p className="ClientMain3">
      (e)
      we are authorised to enter into an agreement with Modulr, acting as your
      agent, for Modulr to provide you with an E-Money Account, and you accept and
      are bound by the E-Money Account Agreement; and
    </p>
    <p className="ClientMain3">
      (f)
      we are authorised to give instructions (as provided for in the E-Money
      Account Agreement and in this Agreement) and provide information concerning you
      to Modulr, and Modulr is entitled to rely on any such instructions or
      information without further enquiry.
      {' '}
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        1.8.
      </span>
      <span lang="EN-GB">
        In this document, references to
        {' '}
        <b>we/our/us</b>
        {' '}
        are to Plend. Any references to
        {' '}
        <b>Borrower/you/your</b>
        {' '}
        are references to you
        as a borrower through the Platform.
        {' '}
      </span>
    </p>
    <p className="ClientMain2">
      <a name="_Hlk90310063">
        <span lang="EN-GB">
          1.9.
        </span>
        <span lang="EN-GB">
          If you would like to discuss any element of this
          Agreement or any part of the services offered by us in more detail, please
          email us at
          {' '}
        </span>
      </a>
      <a href="mailto:support@plend.co.uk"><span lang="EN-GB">support@plend.co.uk.</span></a>
      {' '}
      <span lang="EN-GB">For more information on contacting us, please see <a href="https://plend.co.uk/contact-us/">https://plend.co.uk/contact-us/</a>.</span>
    </p>
    <p className="ClientMain2">
      <a name="_Ref447789510">
        <span lang="EN-GB">
          1.10
        </span>
        <span lang="EN-GB">
          The information on the Platform (and/or our
          website) is not intended for use outside the UK, and no such information
          constitutes an offer or solicitation to anyone in any jurisdiction where such
          an offer is not lawful or to anyone to whom it is unlawful to make such an
          offer or solicitation.
        </span>
      </a>
    </p>
    <p className="ClientMain1">
      <span style={{ color: 'black', textTransform: 'none', fontWeight: 'normal' }}>
        2.
      </span>
      <span lang="EN-GB">Opening a Borrower account</span>
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        2.1.
      </span>
      <span lang="EN-GB">
        Plend is a peer-to-peer lending platform that
        allows borrowers, such as you, to enter into loans (“
        <b>Loans</b>
        ”) funded by
        other users of the platform who have opened a “lender account“ on the Platform
        (“
        <b>Lenders</b>
        ”). Each Loan entered into is subject to terms found here
        [insert link] (the “
        <b>Loan Agreement</b>
        ”).
      </span>
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        2.2.
      </span>
      <span lang="EN-GB">
        You can create an account on the Platform (a “
        <b>
          Borrower
          Account
        </b>
        ”) through which you can enter into Loans. Borrower Accounts are
        subject to the terms of the Agreement.
      </span>
    </p>
    <p className="ClientMain2" style={{ pageBreakAfter: 'avoid' }}>
      <a name="_Ref447789519">
        2.3.
        <span lang="EN-GB">In order to apply to open a Borrower Account you must </span>
      </a>
      be
      <a name="_Ref447789521"> an individual of at least 18 years of age who:</a>
    </p>
    <p className="ClientMain4">
      <a name="_Ref447789522">
        a)
        is resident in the UK;
      </a>
    </p>
    <p className="ClientMain4">
      <a name="_Ref447789523">
        b)
        has a bank account at a bank or building society
      </a>
      {' '}
      in the UK;
    </p>
    <p className="ClientMain4">
      c)
      have sufficient legal capacity to enter into the Agreement; and
    </p>
    <p className="MsoListParagraph">
      d)
      has completed a
      <a name="_Ref479603829">
        credit reference and fraud
        prevention check with a credit reference and fraud prevention agency (which we
        will arrange). This information will be used by Plend to verify your identity,
        assess your creditworthiness and to detect fraud, money laundering or any other
        crime.
      </a>
      <span style={{ fontSize: '11.0pt', fontFamily: 'Neue Haas Grotesk Display Pro' }}> </span>
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        2.4.
      </span>
      <span lang="EN-GB">
        When opening a Borrower Account you will be
        required to enter into a direct debit arrangement under the Payment Processor
        Agreement with GoCardless who will only be able to collect your money from a current
        account in your name held with a bank or building society authorised by a
        regulatory authority in Australia, Canada, the European Union, New Zealand,
        Norway, Sweden, the United Kingdom, or the United States of America to serve as
        your
        &nbsp;
        <b>Nominated Account</b>
        {' '}
        and transfer it
        <b> </b>
        to the E-Money Account
        held in your name with Modulr. Modulr will be responsible for holding and
        transmitting your money to satisfy your payment obligations under any Loan
        Agreement in accordance with the E-Money Account Agreement.
        <b></b>
      </span>
      If
      you wish to change your Nominated Account, you must notify us in writing, and
      you must provide such information/ evidence as we may request to prove that the
      new nominated account is held in your name. Any changes to your Nominated
      Account will only become effective once accepted by us in writing.
      {' '}
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        2.5.
      </span>
      <span lang="EN-GB">
        If your application is successful, we will open
        an account in your name on the Platform. We have the right to decline the
        request to open the account without providing reasons for doing so and with no
        right to any compensation.
      </span>
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        2.6.
      </span>
      <span lang="EN-GB">
        We will carry out "know your client"
        checks and anti-money laundering checks before opening a Borrower Account. We
        may require you to provide additional information (and supporting evidence) in
        carrying out these checks. We may refuse an application to open a Borrower Account
        if we are not satisfied that you fulfil our "know your client" and/or
        anti-money laundering assessment criteria.
      </span>
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        2.7.
      </span>
      <span lang="EN-GB">
        If, in our absolute discretion, we suspect or
        have reason to suspect money laundering or any other illegal activities, we may
        cease to provide you with access to the Platform or any other services under
        this Agreement without any explanation or notice and inform law enforcement or
        other relevant authorities of our suspicions and the reasons for them. This is
        in order for us to comply with our legal obligations.
      </span>
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        2.8.
      </span>
      <span lang="EN-GB">
        Where you are required to provide us with
        personal information or any other information in order to register for the Platform
        or satisfy any eligibility criteria or other ongoing requirements (including in
        relation to any regulatory requirements), you agree that any such information
        you provide will be accurate, complete and current and that you will promptly
        provide us with any changes or updates to this information. You agree you will
        only use and maintain one single account to access or otherwise utilise the Platform,
        and your creation and/or use of any subsequent accounts may result in our
        disabling your accounts and withdrawing your access to the Platform.
      </span>
    </p>
    <p className="ClientMain1">
      <span lang="EN-GB" style={{ color: 'black', textTransform: 'none', fontWeight: 'normal' }}>
        3.
      </span>
      <span lang="EN-GB">open banking</span>
    </p>
    <p className="ClientMain2">
      3.1.
      Open banking is a secure way of providing read-only access to your
      Nominated Account to providers who are registered for this purpose, such as
      Plend. For more information, please visit
      {' '}
      <a href="http://www.openbanking.org.uk">www.openbanking.org.uk</a>
      .
      {' '}
    </p>
    <p className="ClientMain2">
      3.2.
      During your application to open a Borrower Account, you agree that we may
      direct you to Plaid’s secure portal for the purposes of you granting Plaid
      access to your Nominated Account. In order to enter into and administer your
      Loan Agreement and for the purposes of you being able to use the Platform, you
      agree that we may share with Plaid your personal and contact details and
      details you have provided to us in any application to open an a Borrower
      Account. You agree that Plaid may access your Nominated Account and provide
      such information to us as is required for the purposes of this Agreement and
      accessing the Platform. Plaid will access information related to your Nominated
      Account and provide us with such information as is necessary to perform our
      obligations to you under this Agreement in accordance with the Open Banking
      Agreement. Once Plaid has shared the required information deriving from your
      Nominated Account with us, we will continue with the process of opening your
      Borrower Account.
    </p>
    <p className="ClientMain2">
      3.3.
      You will not be required to share your Nominated Account’s banking
      password or log in details with either us or Plaid. Once you have given your
      explicit consent to share your Nominated Account details on Plaid’s portal, you
      will be directed to your own Nominated Account provider’s login pay where you
      will be required to enter in your own login details directly.
    </p>
    <p className="ClientMain1">
      <span lang="EN-GB" style={{ color: 'black', textTransform: 'none', fontWeight: 'normal' }}>
        4.
      </span>
      <span lang="EN-GB">accessing the platform</span>
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        4.1.
      </span>
      <span lang="EN-GB">
        When you open a Borrower Account you will be
        required to select a username and password, which in combination will
        constitute your
        {' '}
        <b>Security Details</b>
        .
      </span>
      You must take all reasonable
      steps to keep your Security Details private at all times and never disclose it
      to anyone. We will never ask you to provide your password to us or to a third
      party and you must never allow anyone to access your account or watch you
      accessing your account. We recommend that you change your password regularly in
      order to limit the risk of your account being compromised.
      {' '}
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        4.2.
      </span>
      You are responsible for monitoring your account and must ensure
      that you read all messages that have been sent to you.If you have any
      indication or suspicion of your account, login details, password or other
      security feature being lost, stolen, misappropriated, used without authorisation,
      or otherwise compromised, you are advised to change your password immediately
      and contact us without undue delay.
    </p>
    <p className="ClientMain2">
      <a name="_Ref30112368">
        4.3.
        We may suspend your account and access to the Platform if we have
        reasonable grounds to suspect:
      </a>
    </p>
    <p className="ClientMain3">
      (a)
      the security of the account or any of its security features have been
      compromised;
    </p>
    <p className="ClientMain3">
      (b)
      an unauthorised or fraudulent use of your account has occurred;
    </p>
    <p className="ClientMain3">
      (c)
      your account has been used in any way that may not comply with any law
      or regulation;
      {' '}
    </p>
    <p className="ClientMain3">
      (d)
      you are not UK resident; or
      {' '}
    </p>
    <p className="ClientMain3">
      (e)
      any of the information or documentation provided by you or on your
      behalf in relation to your identity, financial standing and/ or source of funds
      is misleading, incomplete or inaccurate.
    </p>
    <p className="ClientMain2">
      4.4.
      Where permitted by applicable law, we will usually notify you before any
      suspension or restriction, but we are not obliged to do so. Where we do not
      notify you in advance of a suspension or restriction, we will notify you after
      the suspension or restriction has been imposed. We will remove the suspension
      and/ or the restriction as soon as practicable after the reasons for the
      suspension and/ or restriction no longer exist.
    </p>
    <p className="ClientMain2">
      <a name="_Ref89677620"/>
      <a name="_Ref447789527">
        <span lang="EN-GB">
          4.5.
        </span>
        <span lang="EN-GB">
          You must let us know immediately if you lose, or
          if someone else comes into possession of or has access to, your Security
          Details. In that event (or if we reasonably suspect such event to have
          happened) we may suspend activity on your Borrower Account until the matter is
          resolved.
        </span>
      </a>
    </p>
    <p className="ClientMain2">
      <a name="_Ref30112369">
        <span lang="EN-GB">
          4.6.
        </span>
        You must take all reasonable care to ensure that your email
        account(s) are secure and only accessed by you, as your email address may be used
        in the process of resetting passwords or to communicate with you about the
        security of your account. In case any of the email addresses registered with
        your account are compromised, you should without undue delay after becoming
        aware of this contact us and also contact your email service provider.
      </a>
    </p>
    <p className="ClientMain1">
      <span style={{ color: 'black', textTransform: 'none', fontWeight: 'normal' }}>
        5.
      </span>
      Accessing your borrower account
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        5.1.
      </span>
      <span lang="EN-GB">
        You will be required to provide your Security
        Details whenever you access your Borrower Account. If they are provided
        correctly, we will be entitled to assume that any instructions given thereafter
        are from you, and you will be liable for those instructions unless you notify
        us in accordance with
      </span>
      <b>clauses </b>
      <b>4.5</b>
      {' '}
      and
      {' '}
      <b>4.6</b>.
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        5.2.
      </span>
      <span lang="EN-GB">
        We can refuse to act on any of your instructions
        where we reasonable believe that:
      </span>
    </p>
    <p className="ClientMain3">
      <span lang="EN-GB">
        (a)
      </span>
      <span lang="EN-GB">the instructions are unclear; or </span>
    </p>
    <p className="ClientMain3">
      <span lang="EN-GB">
        (b)
      </span>
      <span lang="EN-GB">the instructions are not given by you; or </span>
    </p>
    <p className="ClientMain3">
      <span lang="EN-GB">
        (c)
      </span>
      we are not satisfied that you fulfil our "know your
      client" and/or anti-money laundering assessment criteria; or
    </p>
    <p className="ClientMain3">
      <span lang="EN-GB">
        (d)
      </span>
      <span lang="EN-GB">
        the instructions would or may cause us to breach
        a legal or other duty; or
        {' '}
      </span>
    </p>
    <p className="ClientMain3">
      <span lang="EN-GB">
        (e)
      </span>
      <span lang="EN-GB">
        if we believe the Platform is being used for an
        illegal purpose.
      </span>
    </p>
    <p className="ClientMain2" style={{ pageBreakAfter: 'avoid' }}>
      <span lang="EN-GB">
        5.3.
      </span>
      <span lang="EN-GB">
        Unless you notify us in accordance with
      </span>
      <b>
        clauses
      </b>
      {' '}

      <b>4.5</b>
      {' '}
      and
      {' '}
      <b>4.6</b>
      <b>:</b>
    </p>
    <p className="ClientMain3">
      <a name="_Ref447789529">
        <span lang="EN-GB">
          (a)
        </span>
        <span lang="EN-GB">
          you will be responsible for any instruction on your Borrower Account
          which we receive and act on, even if it was not given by you; and
        </span>
      </a>
    </p>
    <p className="ClientMain3">
      <a name="_Ref447789530">
        <span lang="EN-GB">
          (b)
        </span>
        <span lang="EN-GB">
          we will not be responsible for any unauthorised access to your Borrower
          Account or the information available in it.
        </span>
      </a>
    </p>
    <p className="ClientMain1">
      <span style={{ color: 'black', textTransform: 'none', fontWeight: 'normal' }}>
        6.
      </span>
      Applying for a loan
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        6.1.
      </span>
      <span lang="EN-GB">
        You can submit an application for a Loan through
        your Borrower Account on the Platform (an “
        <b>Application</b>
        ”). In submitting
        an Application, you will be required to specify the desired characteristics of
        the Loan including, but not limited to, the amount to be borrowed and the
        desired term length. Such characteristics will determine your monthly
        repayments, the total amount payable under the Loan and the representative
        annual percentage rate (“
        <b>APR</b>
        ”), all of which will be provided to you
        before you submit the Application. Where such information relies on
        assumptions, these will be explained to you.
      </span>
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        6.2.
      </span>
      <span lang="EN-GB">Upon your submitting an Application:</span>
    </p>
    <p className="ClientMain3">
      (a)
      we will carry out "know your client" checks and anti-money
      laundering checks. We may require you to provide additional information (and
      supporting evidence) in carrying out these checks;
    </p>
    <p className="ClientMain3">
      (b)
      we will conduct an affordability assessment that assesses whether the
      Loan, on the terms applied for, is affordable for you.
    </p>
    <p className="ClientMain3">
      We may reject
      an Application if you do not (or cannot) fulfil our criteria or requirements regarding
      “know your client”, anti-money laundering or affordability. We may also refuse
      an Application for any other reason at our discretion, acting reasonably.
    </p>
    <p className="ClientMain2">
      6.3.
      Your Borrower Account dashboard (the “
      <b>Dashboard</b>
      ”) willset out
      details of Loans entered into. The information available on the Dashboard in
      respect of each Loan will include outstanding balance, payments made, payments
      due and remaining term. There may be a discrepancy between the information
      displayed on the Dashboard and the actual position and/or a delay in such
      information being updated.
    </p>
    <p className="ClientMain2">
      <a name="_Ref447789508">
        <span lang="EN-GB">
          6.4.
        </span>
        <span lang="EN-GB">
          You are permitted to use the Platform only for
          the purpose of becoming a Borrower. You must not, without our prior consent,
          contact or attempt to contact any Lender, Borrower or any party other than us
          in relation to any Loan. We will not disclose your details to Lenders. We will
          not agree to any request for us to provide the details of any Lender. If you
          are contacted by another party in breach of this obligation, you must contact
          us immediately.
        </span>
      </a>
    </p>
    <p className="ClientMain1" style={{ marginTop: '.25in' }}>
      <span style={{ color: 'black', textTransform: 'none', fontWeight: 'normal' }}>
        7.
      </span>
      Loan Repayment
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        7.1.
      </span>
      <span lang="EN-GB">
        The Loan Agreement will detail the terms on
        which you are obliged to repay the Loan, including, but not limited to, the amount
        and frequency of payments and the applicable interest rate (and how interest is
        calculated).
      </span>
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        7.2.
      </span>
      <span lang="EN-GB">
        You will make all payments due under the terms
        of a Loan Agreement to us through the Platform from your E-Money Account. You
        will be required to enter into direct debit arrangements under which payments will
        be collected by GoCardless from your Nominated Account and transferred to you
        E-Money Account to ensure that your E-Money Account contains sufficient funds
        to enable repayment in accordance with the terms of the Loan Agreement. At any
        time, you are able to request information regarding your balance and request to
        pay the full amount of the Loan, including any accrued interest.
        {' '}
      </span>
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        7.3.
      </span>
      We, or another person acting under our direction, may take steps
      to procure the payment of any debt relating to the Loan, or may on behalf of
      the Lender exercise or enforce any rights in relation to any debt relating to
      the Loan.
    </p>
    <p className="ClientMain1">
      <span style={{ color: 'black', textTransform: 'none', fontWeight: 'normal' }}>
        8.
      </span>
      ARREARS AND DEFAULT ACCOUNTS
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        8.1.
      </span>
      <span lang="EN-GB">
        Where you miss a payment, you will receive an
        automatic notification of the missed payment within 2 Business Days, and we
        request that you contact us straight away to discuss your circumstances. In any
        event, we will contact you within 7 Business days of the missed payment to
        discuss your circumstances.
        {' '}
      </span>
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        8.2.
      </span>
      <span lang="EN-GB">
        Where at least one payment has been missed
        without our prior authorisation, your account will be classified as falling
        into arrears and the following steps will be taken:
      </span>
    </p>
    <p className="ClientMain3">
      (a)
      we will communicate to you that your account is in arrears, the amount,
      and how to bring your account back up to date along with possible consequences
      for remaining in arrears. The daily interest whilst your account is in arrears
      will also be communicated to you;
    </p>
    <p className="ClientMain3">
      (b)
      we will assign an account manager to your account who will be
      responsible for liaising with you whilst your account is in arrears;
    </p>
    <p className="ClientMain3">
      (c)
      one of the following actions will take place:
    </p>
    <p className="ClientMain4">
      i.
      if you have notified us that you are experiencing financial
      difficulties, or a payment is missed and financial hardship is suspected, then
      we will contact you to put a more suitable payment arrangement in place. No
      further payments will be taken until we have done this;
    </p>
    <p className="ClientMain4">
      ii.
      where an account remains in arrears with no action being taken by you to
      remedy the situation and in the absence of an adequate explanation or
      communication; a letter will be sent to you advising you of the possible legal
      and contractual consequences of defaulting on the account and the potential
      negative effect on your credit score. Please note that we may need to refer
      your account to a third party for full recovery of repayments and we will give
      you notice of this and all information as required.
    </p>
    <p className="ClientMain1">
      <a name="_Ref447789548">
        <span lang="EN-GB" style={{ color: 'black', textTransform: 'none', fontWeight: 'normal' }}>
          9.
        </span>
        <span lang="EN-GB">OUR FEES</span>
      </a>
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        9.1.
      </span>
      Information regarding any fees that will be charged to you/ your
      account can be found here
      {' '}
      <span lang="EN-GB"><a href='https://plend.co.uk/borrow/'>https://plend.co.uk/borrow/</a>.</span>
    </p>
    <p className="ClientMain1" style={{ pageBreakAfter: 'avoid' }}>
      <a name="_Ref447789586">
        <span lang="EN-GB" style={{ color: 'black', textTransform: 'none', fontWeight: 'normal' }}>
          10.
        </span>
        <span lang="EN-GB">Warranties and Representations</span>
      </a>
    </p>
    <p className="ClientMain2">
      <a name="_Ref447789588"/>
      <a name="_Ref447789589"/>
      <a name="_Ref447789560"/>
      <a name="_Ref447789587">
        10.1. By entering into the Agreement, you represent and warrant to us that:
      </a>
    </p>
    <p className="ClientMain3">
      (a)
      you have capacity to enter into it and any transaction contemplated
      under it;
    </p>
    <p className="ClientMain3">
      (b)
      you have read and understand the Agreement and understand the risks
      involved in entering into the transactions contemplated by it;
    </p>
    <p className="ClientMain3">
      (c)
      you understand and acknowledge that although the Platform is provided to
      enable you to borrow money, we do not guarantee the availability of any Loan
      that you apply for;
    </p>
    <p className="ClientMain3">
      (d)
      you have read, understand and acknowledge the maximum amount of money
      that you can borrow in respect of any Loans that you enter into, details of
      which can be found here
      <span lang="EN-GB">[insert link].</span>
      <span lang="EN-GB"/>
    </p>
    <p className="ClientMain3">
      (e)
      you understand that you may incur fees for breach of the terms of any
      Loan entered into (including, but not limited to, for late or non-payment of
      amounts due) and that you are liable for these;
    </p>
    <p className="ClientMain3">
      (f)
      you are entering into the Agreement as principal and not on behalf of
      any third party;
    </p>
    <p className="ClientMain3">
      <a name="_Ref447789590">
        (g)
        you will not violate any law, ordinance, charter, by-law or rule
        applicable by entering into the Agreement;
      </a>
    </p>
    <p className="ClientMain3">
      (h)
      you understand and acknowledge that while we make reasonable endeavours
      to ensure the accuracy of the information that we are provided with, and which
      in turn, is provided to you, neither we nor any of our or directors or
      employees make any representation or warranty, express or implied, as to the
      accuracy or completeness of such information;
    </p>
    <p className="ClientMain3">
      <a name="_Ref447789592">
        (i)
        you will not use any information provided to you through (or available
        on) the Platform for any purpose other than entering into Loans; and
      </a>
    </p>
    <p className="ClientMain3">
      <a name="_Ref447789593">
        (j)
        any information provided by you to us is complete, accurate and up to
        date.
      </a>
    </p>
    <p className="ClientMain1" style={{ pageBreakAfter: 'avoid' }}>
      <a name="_Ref449031472">
        <span lang="EN-GB" style={{ color: 'black', textTransform: 'none', fontWeight: 'normal' }}>
          11.
        </span>
        <span lang="EN-GB">WITHDRAWAL</span>
      </a>
    </p>
    <p className="ClientMain2">
      <a name="_Ref395693438">
        <span lang="EN-GB">
          11.1.
        </span>
        <span style={{ color: 'black' }}>
          You may exercise your right to withdraw
          from a Loan by sending an email to
        </span>
      </a>
      <a href="mailto:support@plend.co.uk"><span lang="EN-GB">support@plend.co.uk</span></a>
      <span style={{ color: 'black' }}>
        in the 14-day
        period after you have entered into it.
        {' '}
      </span>
    </p>
    <p className="ClientMain2">
      <a name="_Ref476215761">
        <span lang="EN-GB">
          11.2.
        </span>
        <span lang="EN-GB">
          If you exercise your withdrawal rights in
          accordance with
        </span>
        <b>clause</b>
      </a>
      {' '}
      <b>11.1</b>
      <span lang="EN-GB"> above, </span>
      <span style={{ color: 'black' }}>
        you will be required to repay the Loan immediately along
        with any other amounts owed according to its terms
      </span>
      <span lang="EN-GB">.</span>
      <span lang="EN-GB"> </span>
    </p>
    <p className="ClientMain1">
      <span style={{ color: 'black', textTransform: 'none', fontWeight: 'normal' }}>
        12.
      </span>
      AVAILABILITY OF THE PLATFORM
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        12.1.
      </span>
      <span lang="EN-GB">
        We will take all reasonable steps to ensure that
        the Platform is available for you to use at any time. However, for various
        reasons there may be times where the Platform may not be available, such as due
        to technical difficulties where we may not be able to receive your instructions
        or allow you to access the Platform. If this happens, where possible we will
        seek to provide you with notice in advance or as soon as we can. You can also
        contact us by email at
        {' '}
      </span>
      <a href="mailto:support@plend.co.uk"><span lang="EN-GB">support@plend.co.uk</span></a>
      <span lang="EN-GB">. </span>
    </p>
    <p className="ClientMain1">
      <a name="_Ref447789597">
        <span lang="EN-GB" style={{ color: 'black', textTransform: 'none', fontWeight: 'normal' }}>
          13.
        </span>
        <span lang="EN-GB">termination</span>
      </a>
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        13.1.
      </span>
      <span lang="EN-GB">
        The Agreement shall remain valid and binding on
        you for as long as you have entered into one or more Loans that have not been
        repaid by you.
        {' '}
      </span>
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        13.2.
      </span>
      <span lang="EN-GB">
        You may terminate the Agreement provided that
        you have no outstanding Loans requiring repayment. To terminate the Agreement you
        must provide notice in writing to
      </span>
      <a href="mailto:support@plend.co.uk">support@plend.co.uk</a>
      <span lang="EN-GB">.</span>
      <span lang="EN-GB">
        If you provide us with notice of
        termination while you have outstanding Loans for repayment:
      </span>
    </p>
    <p className="ClientMain3">
      (a)
      termination will only take effect upon repayment of the last Loan,
      pending such time the Agreement will remain in effect and binding on you; and
    </p>
    <p className="ClientMain3">
      (b)
      you will not be able to enter into any new Loans unless you first give
      us (and we acknowledge receipt of) notice of your desire to revoke the
      termination instruction.
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        13.3.
      </span>
      <span lang="EN-GB">
        We may terminate the Agreement on giving you at
        least two months’ notice unless the following apply (or we reasonably suspect
        them to), in which case we may terminate the Agreement with immediate effect:
        {' '}
      </span>
    </p>
    <p className="ClientMain3">
      (a)
      you fail to comply with the Agreement;
    </p>
    <p className="ClientMain3">
      (b)

      you are unable to pay your debts as they become due or subject to any
      order or petition for bankruptcy (or equivalent) or have entered into any
      arrangement or composition with creditors;
    </p>
    <p className="ClientMain3">
      (c)
      you have acted fraudulently or negligently;
      {' '}
    </p>
    <p className="ClientMain3">
      (d)
      someone else is trying to access your
      <span lang="EN-GB">Borrower</span>
      Account;
    </p>
    <p className="ClientMain3">
      (e)
      someone else knows or is using your Security Details;
    </p>
    <p className="ClientMain3">
      (f)
      our relationship has broken down because of your actions, e.g. you’ve
      threatened our staff; or
    </p>
    <p className="ClientMain3">
      (g)
      we are ordered to do so by a legal, regulatory body or court.
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        13.4.
      </span>
      <span lang="EN-GB">
        If you have outstanding Loans upon termination
        of the Agreement by us, however effected, we will arrange for you to make early
        repayment of any such Loans.
      </span>
    </p>
    <p className="ClientMain1" style={{ pageBreakAfter: 'avoid' }}>
      <a name="_Ref447789634">
        <span lang="EN-GB" style={{ color: 'black', textTransform: 'none', fontWeight: 'normal' }}>
          14.
        </span>
        <span lang="EN-GB">Liability</span>
      </a>
    </p>
    <p className="ClientMain2">
      <a name="_Ref447789635"/>
      <a name="_Ref447789636">
        <span lang="EN-GB">
          14.1.
        </span>
        <span lang="EN-GB">
          We shall only be liable to you for any loss or
          damage which results directly from our breach of these Terms and Conditions or
          our negligence, fraud or wilful misconduct.
        </span>
      </a>
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        14.2.
      </span>
      <span lang="EN-GB">
        You shall be liable to us for any loss or damage
        suffered by us as a result of your breach of these Terms and Conditions (or any
        other document constituting our agreement with you in respect of your use of
        the Platform) or for your negligence or wilful misconduct.
      </span>
    </p>
    <p className="ClientMain2">
      <a name="_Ref447789637">
        <span lang="EN-GB">
          14.3.
        </span>
        <span lang="EN-GB">
          Whilst we take every care to ensure that the
          standard of the Platform remains high and to maintain the continuity of it, the
          internet is not always a stable medium, and errors, omissions, interruptions of
          service and delays may occur at any time. As a result, we do not accept any
          ongoing obligation or responsibility to operate the Platform (or any particular
          part of it).
        </span>
      </a>
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        14.4.
      </span>
      <span lang="EN-GB">
        You agree and acknowledge that you will continue
        to be solely liable for and responsible for the rights and obligations in
        relation to any Loan and any deed of novation.
        {' '}
      </span>
    </p>
    <p className="ClientMain2">
      <a name="_Ref447789639">
        <span lang="EN-GB">
          14.5.
        </span>
        <span lang="EN-GB">
          We will not be liable for any indirect,
          incidental, special, punitive or consequential damages, loss of business, loss
          of profits, loss or corruption of data, loss of goodwill or reputation caused
          by any act or omission by us under these Terms and Conditions or under any
          Loan.
        </span>
      </a>
    </p>
    <p className="ClientMain2">
      <a name="_Ref447789640">
        <span lang="EN-GB">
          14.6.
        </span>
        <span lang="EN-GB">
          Nothing in these Terms and Conditions shall
          limit any party’s liability for personal injury or death, or for any other
          liability which may not be excluded by English law.
        </span>
      </a>
    </p>
    <p className="ClientMain2">
      <a name="_Ref447789641">
        <span lang="EN-GB">
          14.7.
        </span>
        <span lang="EN-GB">
          No party shall be liable for any failure or
          delay in the performance of any obligation under these Terms and Conditions or
          any Loan (except any payment obligation) by reason of any cause beyond that
          party’s reasonable control.
        </span>
      </a>
    </p>
    <p className="ClientMain1">
      <a name="_Ref447789650">
        <span lang="EN-GB" style={{ color: 'black', textTransform: 'none', fontWeight: 'normal' }}>
          15.
        </span>
        <span lang="EN-GB">AMENDMENTS </span>
      </a>
    </p>
    <p className="ClientMain2">
      <a name="_Ref447789662">
        <span lang="EN-GB">
          15.1.
        </span>
        <span lang="EN-GB">
          We may amend these Terms and Conditions by
          giving you notice in accordance with
        </span>
      </a>
      <b>clause </b>
      <b>19.3</b>.
    </p>
    <p className="ClientMain2" style={{ pageBreakAfter: 'avoid' }}>
      <a name="_Ref447789663">
        <span lang="EN-GB">
          15.2.
        </span>
        <span lang="EN-GB">Such changes</span>
      </a>
      <a name="_Ref447789664"/>
      <span lang="EN-GB"> to these Terms and Conditions may take place where required by law</span>
      <a name="_Hlk89776220">
        or for the following reasons (but are not limited to such
        reasons
      </a>
      <span lang="EN-GB">)</span>
      <span lang="EN-GB">:</span>
    </p>
    <p className="ClientMain3">
      <span lang="EN-GB">
        (a)
      </span>
      {' '}
      <span lang="EN-GB">
        to improve the clarity of these Terms and Conditions
        but on terms no less favourable to you than the previous wording;
        {' '}
      </span>
    </p>
    <p className="ClientMain3">
      <span name="_Ref447789665"><span lang="EN-GB">
        (b)</span>
        {' '}
        <span lang="EN-GB">to</span>
      </span>
      <a name="_Ref447789666"/>
      <span lang="EN-GB">
        {' '}
        introduce
        new products or services
      </span>
      <span lang="EN-GB">;</span>
    </p>
    <p className="ClientMain3">
      <span lang="EN-GB">
        (c)
      </span>
      {' '}
      <span lang="EN-GB">to improve existing products or services; or</span>
    </p>
    <p className="ClientMain3">
      <span lang="EN-GB">
        (d)
      </span>
      {' '}
      <span lang="EN-GB">
        where reasonably required by a third party
        service provider.
      </span>
    </p>
    <p className="ClientMain1">
      <span style={{ color: 'black', textTransform: 'none', fontWeight: 'normal' }}>
        16.
      </span>
      Privacy policy
    </p>
    <p className="ClientMain2">
      16.1.
      Any personal information that you provide to us or which we otherwise
      obtain will be dealt with in line with our privacy policy, which explains what
      personal information we collect from you or from other sources, how and why we
      collect, store, use and share such information, your rights in relation to your
      personal information and how to contact us and supervisory authorities in the
      event you have a query or complaint about the use of your personal information.
    </p>
    <p className="ClientMain2">
      16.2. Our privacy policy is available here {' '}
      <a href="https://www.plend.co.uk/privacy-policy/">https://www.plend.co.uk/privacy-policy/</a>
      .
    </p>
    <p className="ClientMain1" style={{ pageBreakAfter: 'avoid' }}>
      <span lang="EN-GB" style={{ color: 'black', textTransform: 'none', fontWeight: 'normal' }}>
        17.
      </span>
      <span lang="EN-GB">Complaints PROCEDURE</span>
    </p>
    <p className="ClientMain2">
      <a name="_Ref447789651">
        <span lang="EN-GB">
          17.1.
        </span>
        <span lang="EN-GB">
          If you want to make a complaint about us you
          should email us at
          {' '}
        </span>
      </a>
      <a href="mailto:complaints@plend.co.uk"><span lang="EN-GB">complaints@plend.co.uk</span></a>
      <span lang="EN-GB">
        {' '}
        with details of
        your complaint and details of your Borrower Account. We will then investigate
        and send you an initial response, having had access to an officer with the
        authority to settle the complaint (including, where appropriate, an offer of
        redress).
      </span>
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        17.2.
      </span>
      <span lang="EN-GB">
        We aim to resolve complaints by close of
        business on the third Business Day after the complaint is received. If we
        cannot resolve it in this time will confirm receipt of it by close of business
        on the fifth Business Day and keep you up to date with our progress in
        responding to it. We will endeavour to provide you with a final response to
        your complaint within eight weeks of receiving it.
        {' '}
      </span>
    </p>
    <p className="ClientMain2" style={{ pageBreakAfter: 'avoid' }}>
      <a name="_Ref447789654">
        <span lang="EN-GB">
          17.3.
        </span>
        <span lang="EN-GB">
          If you are unhappy with our response, you may
          have the right to contact the Financial Ombudsman Service at:
          {' '}
        </span>
      </a>
    </p>
    <h2>
      <span lang="EN-GB">
        The Financial
        Ombudsman Service
      </span>
    </h2>
    <h2><span lang="EN-GB">Exchange Tower</span></h2>
    <h2><span lang="EN-GB">London E14 9SR</span></h2>
    <h2>
      <span lang="EN-GB">
        Tel: 080 0023
        4567
      </span>
    </h2>
    <h2>
      <span lang="EN-GB">
        Email:
        complain.info@financial-ombudsman.org.uk
      </span>
    </h2>
    <p className="ClientMain1" style={{ pageBreakAfter: 'avoid' }}>
      <span lang="EN-GB" style={{ color: 'black', textTransform: 'none', fontWeight: 'normal' }}>
        18.
      </span>
      <span lang="EN-GB">No partnership or agency </span>
    </p>
    <p className="ClientMain2">
      <a name="_Ref447789535">
        <span lang="EN-GB">
          18.1.
        </span>
        <span lang="EN-GB">
          Except as may be expressly provided here,
          nothing in the Agreement or any Loan is intended to, or shall be deemed to,
          establish any partnership or joint venture between us and you. It does not authorise
          either party to make or enter into any commitments for or on behalf of any
          other party.
        </span>
      </a>
    </p>
    <p className="ClientMain1" style={{ pageBreakAfter: 'avoid' }}>
      <span lang="EN-GB" style={{ color: 'black', textTransform: 'none', fontWeight: 'normal' }}>
        19.
      </span>
      <span lang="EN-GB">Notices</span>
    </p>
    <p className="ClientMain2">
      <a name="_Ref447789659">
        <span lang="EN-GB">
          19.1.
        </span>
        <span lang="EN-GB">
          We may (where allowed to do so by Law)
          communicate with you by posting information in your Borrower Account or on the
          Platform, in which case the information will be treated as received by you when
          it is posted by us.
        </span>
      </a>
      <span lang="EN-GB">
        {' '}
        We may also contact you at the
        address you provide us with on opening your Borrower Account (or as updated by
        you from time to time).
      </span>
    </p>
    <p className="ClientMain2">
      <a name="_Ref447789660">
        <span lang="EN-GB">
          19.2.
        </span>
        <span lang="EN-GB">
          Any communication given to us shall be deemed to
          have been received only on actual receipt by us.
        </span>
      </a>
    </p>
    <p className="ClientMain2" style={{ pageBreakAfter: 'avoid' }}>
      <a name="_Ref447812674">
        <span lang="EN-GB">
          19.3.
        </span>
        <span lang="EN-GB">
          Any notice or other communication given to a
          party under or in connection with the Agreement shall be:
        </span>
      </a>
    </p>
    <p className="ClientMain3">
      <a name="_Ref447812675">
        <span lang="EN-GB">
          (a)
        </span>
        <span lang="EN-GB">in writing;</span>
      </a>
    </p>
    <p className="ClientMain3">
      <a name="_Ref447812676">
        <span lang="EN-GB">
          (b)
        </span>
        <span lang="EN-GB">
          delivered by hand, by pre-paid first-class post or other next
          working day delivery service or sent by email; and
        </span>
      </a>
    </p>
    <p className="ClientMain3" style={{ pageBreakAfter: 'avoid' }}>
      <a name="_Ref447812680">
        <span lang="EN-GB">
          (c)
        </span>
        <span lang="EN-GB">deemed to have been received:</span>
      </a>
    </p>
    <p className="ClientMain4">
      <a name="_Ref447812681">
        i.
        <span lang="EN-GB">
          if delivered by hand, at the time it is left at the
          relevant address;
        </span>
      </a>
    </p>
    <p className="ClientMain4">
      <a name="_Ref447812682">
        ii.
        <span lang="EN-GB">
          if posted by pre-paid first-class post or other next
          working day delivery service, on the second business day after posting;
          {' '}
        </span>
      </a>
      <span lang="EN-GB">and</span>
    </p>
    <p className="ClientMain4">
      iii.
      <span lang="EN-GB">if sent by email, when received.</span>
    </p>
    <p className="ClientMain1">
      <a name="_Ref447789691">
        <span lang="EN-GB" style={{ color: 'black', textTransform: 'none', fontWeight: 'normal' }}>
          20.
        </span>
        <span lang="EN-GB">Financial Services COmpensation scheme</span>
      </a>
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        20.1.
      </span>
      <span lang="EN-GB">
        Unless otherwise stated, the Loans available on
        the Platform will not be covered by the Financial Services Compensation Scheme
        (the
        <b>"FSCS"</b>
        ).
        {' '}
      </span>
    </p>
    <p className="ClientMain1">
      <span style={{ color: 'black', textTransform: 'none', fontWeight: 'normal' }}>
        21.
      </span>
      WAIVER
    </p>
    <p className="ClientMain2">
      21.1.
      Any delay or failure to exercise any power, right or remedy by us under
      these Terms and Conditions will not operate as a waiver of that power, right or
      remedy, nor will it impair or prejudice it.
    </p>
    <p className="ClientMain2">
      21.2.
      No single or partial exercise, or failure or delay in exercising any
      right, power or remedy by either you or us shall constitute a waiver by us of,
      or preclude any further exercise of, that or any right, power or remedy arising
      under these Terms and Conditions or otherwise.
    </p>
    <p className="ClientMain1">
      <span lang="EN-GB" style={{ color: 'black', textTransform: 'none', fontWeight: 'normal' }}>
        22.
      </span>
      <span lang="EN-GB">General</span>
    </p>
    <p className="ClientMain2">
      <a name="_Ref447789693"/>
      <a name="_Ref447789697">
        <span lang="EN-GB">
          22.1.
        </span>
        <span lang="EN-GB">
          The records kept by us on the Platform shall be
          conclusive of the facts and matters they purport to record except where there
          is an obvious mistake.
        </span>
      </a>
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        22.2.
      </span>
      <span lang="EN-GB">
        In the case of any inconsistency between these Terms
        and Conditions and any other document forming the Agreement between us in
        respect of your use of the Platform, these Terms and Conditions shall prevail.
      </span>
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        22.3.
      </span>
      <span lang="EN-GB">
        Nothing in these Terms and Conditions is
        intended to confer any benefit on any person who is not a party, and no third
        party shall have any rights under the Contracts (Rights of Third Parties) Act
        1999 to enforce any of the terms.
      </span>
    </p>
    <p className="ClientMain2">
      <a name="_Ref447789694">
        <span lang="EN-GB">
          22.4.
        </span>
        <span lang="EN-GB">
          If any part of these Terms and Conditions is
          found to be illegal, invalid or unenforceable by any court of competent
          jurisdiction or regulatory authority, then such term will be severed from the
          remaining Terms and Conditions which will continue to be valid, as far as
          permitted by English law.
          {' '}
        </span>
      </a>
    </p>
    <p className="ClientMain2">
      <span lang="EN-GB">
        22.5.
      </span>
      <span lang="EN-GB">
        The rights and remedies provided under these Terms
        and Conditions are in addition to any rights and remedies available under English
        law.
      </span>
    </p>
    <p className="ClientMain2">
      <a name="_Ref447789695">
        <span lang="EN-GB">
          22.6.
        </span>
        <span lang="EN-GB">
          Our rights and obligations under these Terms and
          Conditions are personal to you which means that you cannot assign them to
          another party without our explicit prior written consent. You
          {' '}
        </span>
      </a>
      <a name="_Ref447789696"/>
      <span lang="EN-GB">
        agree that we may assign our
        respective rights and obligations under these Terms and Conditions or to any
        third party without notice.
      </span>
    </p>
    <p className="ClientMain2">
      <a name="_Ref447789698">
        <span lang="EN-GB">
          22.7.
        </span>
        <span lang="EN-GB">
          These Terms and Conditions are governed by
          English law and the courts of England and Wales will have exclusive
          jurisdiction to settle any disputes that may arise in relation to them,
          provided that we retain the right to bring proceedings against you for a breach
          of these Terms and Lender conditions in your country of residence or any other
          relevant country.
        </span>
      </a>
    </p>
    <span lang="EN-GB" style={{ fontSize: '10.0pt', fontFamily: 'Neue Haas Grotesk Display Pro' }}>
      <br clear="all" style={{ pageBreakBefore: 'always' }}/>
    </span>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="DocumentTitle">
      <a name="_Hlk100764365"/>
      <a name="_Hlk100762381"><span lang="EN-GB">annex 1 – payment processor agreement</span></a>
    </p>
    <p className="MsoNormal" align="center"
       style={{ textAlign: 'center', punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>
        <span lang="EN-GB">
          GoCardless
          Limited
        </span>
      </b>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}><b><span
      lang="EN-GB"></span></b></p>
    <p className="MsoNormal" align="center"
       style={{ textAlign: 'center', punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>
        <span lang="EN-GB">
          Instant
          Bank Pay payer terms of use
        </span>
      </b>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>
          <span lang="EN-GB">
            What
            are these terms and who do they apply to?
            {' '}
          </span>
      </b>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        These terms
        (Terms) apply to you (you, your) when you use the Instant Bank Pay payment
        initiation service provided by GoCardless Limited (GoCardless, we, us, our) to
        make a one-off payment to a merchant we also have a relationship with
        (Merchant). Every time you use Instant Bank Pay you must agree to the Terms in
        force at that time, so please be sure to read them carefully each time. If you
        do not agree to these Terms, you cannot use the payment initiation service.
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>
          <span lang="EN-GB">
            What
            is Instant Bank Pay?
            {' '}
          </span>
      </b>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        Instant Bank
        Pay is a payment initiation service which is a service where we initiate a
        payment from your bank account on your behalf. It enables you to pay a Merchant
        directly from your bank account rather than using your debit or credit card
        details through a third party. You must give us your explicit consent before we
        can provide Instant Bank Pay to you.
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>
          <span lang="EN-GB">
            Who is
            providing Instant Bank Pay to you?
            {' '}
          </span>
      </b>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        GoCardless
        is providing Instant Bank Pay to you. The address of our head office is:
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">Sutton Yard </span></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        65 Goswell
        Road
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">London </span></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">EC1V 7EN </span></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        GoCardless
        is authorised and regulated by the Financial Conduct Authority under the
        Payment Services Regulations 2017 (Firm Reference Number: 597190).
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        In using
        Instant Bank Pay, you understand and agree that: (i) we are providing it to you
        only and you should not share your access to Instant Bank Pay with anyone else;
        (ii) you must not use Instant Bank Pay for any fraudulent, unlawful or abusive
        purpose; and (iii) you must only use Instant Bank Pay in relation to your own
        payment accounts using your own security details for those accounts.
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        In providing
        Instant Bank Pay, we will do so with reasonable care and skill, although we do
        not make any particular commitments or promises to you about Instant Bank Pay,
        including its reliability or availability or that it will be suitable for your
        needs. Notwithstanding this, your statutory rights remain unaffected.
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>
          <span lang="EN-GB">
            How
            does Instant Bank Pay work and how will GoCardless access my bank account?
          </span>
      </b>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        Once you
        have given us your explicit consent to do so, we will instruct your bank to
        make a payment to a Merchant from your selected payment account.
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        You will be
        sent an email or a paylink asking you to use Instant Bank Pay to make a one-off
        instant payment to the Merchant. If you accept the Instant Bank Pay option
        requested by the Merchant by following the instructions in the email or
        paylink, you will be directed to a page to select your bank and the payment
        account you want to make the payment from. Details of both the amount and the
        Merchant will be displayed on the account selection page. By selecting your
        bank and payment account you want to make the payment from, you are consenting
        to use Instant Bank Pay and instruct GoCardless to initiate a payment of the
        specified amount to the Merchant and we will redirect you to your bank so you
        can provide them with your account login and security details (
        <b>
          Security
          Details
        </b>
        ) and go through authentication with them. We will never ask you to
        share your Security Details with us, and neither GoCardless nor the Merchant
        will be able to see or access your Security Details at any time. Your bank will
        only act on the instruction to make the payment after you give them your
        Security Details and successfully authenticate the payment with them. Once your
        bank has confirmed authentication, we V0421.0 2/3 will instruct your bank to
        make the payment in accordance with your instructions and your bank will
        execute the payment from your payment account.
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>
          <span lang="EN-GB">
            How
            long will it take for my payment to reach the Merchant?
          </span>
      </b>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        Once you
        have authorised the payment with your bank you will not be able to cancel it.
        Funds will usually leave your payment account within 2 hours of successful
        initiation, although it could be longer depending on how long your bank takes
        to execute the payment order. We will confirm the successful initiation of the
        payment order, but it is your bank (not GoCardless) who is responsible for
        executing it.
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>
          <span lang="EN-GB">
            Can we
            refuse to make a payment for you using Instant Bank Pay?
            {' '}
          </span>
      </b>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        Yes, we may
        refuse to initiate a payment for you using Instant Bank Pay if we think the
        conditions set out in these Terms are not satisfied, or if it would be unlawful
        to initiate the payment.
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>
          <span lang="EN-GB">
            What
            happens if something goes wrong?
            {' '}
          </span>
      </b>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        If,
        following use of Instant Bank Pay, you notice unauthorised or suspicious
        transactions on your payment account, you should immediately contact your bank,
        who is responsible for investigating it and, where necessary, reimbursing you.
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        If you
        suspect somebody else has access to your Security Details and is fraudulently
        using them to access Instant Bank Pay you must contact us immediately by email
        at
        {' '}
      </span>
      <a href="mailto:help@gocardless.com"><span lang="EN-GB">help@gocardless.com</span></a>
      <span lang="EN-GB">. </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        Once you
        have consented to us initiating the payment you cannot revoke your consent and
        there is no way of charging back transactions made to Merchants made using
        Instant Bank Pay. If the funds have not left your payment account you may be
        able to stop the transaction by contacting your bank, but that would be for
        your bank (not GoCardless) to decide.
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        GoCardless
        will not be responsible for any loss you may suffer as a result of your
        material failure to comply with these Terms or caused by matters beyond our
        reasonable control (e.g. pandemic, industrial action, natural disaster etc). We
        are only responsible to you for foreseeable loss and damage caused by us and
        not for any indirect or consequential losses, although we do not exclude or
        limit our liability in any way if it would be unlawful to do so.
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        You agree
        you will be liable to us for any losses which we can show were sustained by us
        as a direct result of your breach of these Terms.
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>
          <span lang="EN-GB">
            What
            is the law and jurisdiction governing these Terms?
            {' '}
          </span>
      </b>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        These Terms
        are governed by English law and the English courts have exclusive jurisdiction
        over any matter, claim or dispute (whether contractual or non-contractual)
        arising out of or in connection with the Terms or their subject matter or
        formation.
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        What do I do
        if I want to make a complaint about Instant Bank Pay?
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        If you wish
        to make a complaint about Instant Bank Pay, please contact us first using the
        contact details below:
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        Please
        submit complaints via email to:
        {' '}
      </span>
      <a href="mailto:complaints@gocardless.com"><span lang="EN-GB">complaints@gocardless.com</span></a>
      <span lang="EN-GB"> </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        Complaints
        can also be submitted in writing to:
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        Complaints
        Team
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        GoCardless
        Ltd
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        65 Goswell
        Road
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">London </span></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">EC1V 7EN </span></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        We will do
        our best to resolve your complaint, but if you still aren’t happy with our
        response and you are a consumer, micro-enterprise or other eligible
        complainant, you have the right to refer your case to the Financial Ombudsman
        Service:
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        Financial
        Ombudsman Service
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        Exchange
        Tower
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">London </span></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">E14 9SR </span></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        Tel: 0800
        023 4567
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">Website: </span>
      <a href="http://www.financial-ombudsman.org.uk"><span lang="EN-GB">www.financial-ombudsman.org.uk</span></a>
      <span lang="EN-GB"> </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>
          <span lang="EN-GB">
            What
            are the Financial Conduct Authority’s contact details?
            {' '}
          </span>
      </b>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        The
        Financial Conduct Authority’s contact details are:
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        Financial
        Conduct Authority
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        12 Endeavour
        Square London,
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">E20 1JN </span></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        Contact
        Centre: 0300 500 0597
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <span lang="EN-GB">
        Consumer
        Helpline: 0800 111 676
        <br clear="all" style={{ pageBreakBefore: 'always' }}/>
      </span>
    </p>
    <p className="DocumentTitle"><span lang="EN-GB">annex 2 – open banking agreement</span></p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        This End User Services Agreement ("
        <b><span style={{ border: 'none windowtext 1.0pt', padding: '0in' }}>Agreement</span></b>
        ") is
        a legal agreement between Plaid Financial Ltd. ("
        <b><span style={{ border: 'none windowtext 1.0pt', padding: '0in' }}>Plaid</span></b>
        ", "
        <b><span style={{ border: 'none windowtext 1.0pt', padding: '0in' }}>we</span></b>
        " or "
        <b><span style={{ border: 'none windowtext 1.0pt', padding: '0in' }}>us</span></b>
        ") and the
        end user of our Services ("
        <b><span style={{ border: 'none windowtext 1.0pt', padding: '0in' }}>you</span></b>
        ", "
        <b><span style={{ border: 'none windowtext 1.0pt', padding: '0in' }}>your</span></b>
        "). This Agreement only applies when we
        provide account information services and payment initiation services to you
        with respect to a UK payment account, and when we transmit retrieved data
        and/or the payment status related to such services to third parties on your
        behalf ("
        <b><span style={{ border: 'none windowtext 1.0pt', padding: '0in' }}>Services</span></b>
        ").
        Such third parties include the owner or provider of the website, desktop and/or
        mobile application through which you have accessed our Services ("
        <b><span style={{ border: 'none windowtext 1.0pt', padding: '0in' }}>Application</span></b>
        ").
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        This Agreement describes the terms and
        conditions that apply to your use of the Services. You may not access or use
        the Services unless you agree to comply with all of the terms and conditions in
        this Agreement. You should therefore read this Agreement carefully and make
        sure you understand it. If you do not understand any of the terms and
        conditions of this Agreement, please contact us before using the Services by
        emailing us at
      </span>
      <a href="mailto:regulatory@plaid.com"><span lang="EN-GB" style={{
       border: 'none windowtext 1.0pt', padding: '0in'
      }}>{' '}regulatory@plaid.com</span></a>
      <span lang="EN-GB">.</span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        You may view an up-to-date copy of this
        Agreement at any time at
      </span>
      <a href="https://plaid.com/legal/#end-user-services-agreement-uk"><span lang="EN-GB" style={{
        border: 'none windowtext 1.0pt', padding: '0in'
      }}>{' '}https://plaid.com/legal/#end-user-services-agreement-uk</span></a>
      <span lang="EN-GB">.</span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <b>
        <span lang="EN-GB" style={{ color: '#383838', border: 'none windowtext 1.0pt', padding: '0in' }}>
          1.
          Plaid
        </span>
      </b>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        1.1. Plaid Financial Ltd. (company
        registration number 11103959) is authorised by the Financial Conduct Authority
        under the Payment Services Regulations 2017 for the provision of payment
        initiation and account information services (firm reference number 804718).
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        1.2. Plaid's registered office address is New
        Penderel House, 4th Floor, 283-288 High Holborn, London WC1V 7HP. Plaid's
        trading address is 35-41 Folgate Street, London, E1 6BX.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <b>
        <span lang="EN-GB" style={{ color: '#383838', border: 'none windowtext 1.0pt', padding: '0in' }}>
          2.
          Plaid Services
        </span>
      </b>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.1. You have accessed our Services through an
        Application or within the Plaid dashboard (available at
      </span>
      <a href="https://my.plaid.com"><span lang="EN-GB" style={{ border: 'none windowtext 1.0pt', padding: '0in'
      }}>{' '}https://my.plaid.com/</span></a>
      <span lang="EN-GB">
        ). The products and services provided to you by the Application
        are governed by a separate agreement between you and the provider of the
        Application ("
        <b>
          <span style={{ border: 'none windowtext 1.0pt', padding: '0in' }}>
            Application
            Terms
          </span>
        </b>
        "). We have no responsibility for the products and
        services provided to you by or through the Application and will not be liable
        to you for any harm, damage or loss arising from your use of the products and
        services provided by or through the Application.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.2. Our Services will allow you to obtain
        financial information from your online bank or payment account ("
        <b><span style={{ border: 'none windowtext 1.0pt', padding: '0in' }}>Payment Account</span></b>
        ")
        (account information services) and to make online payments directly from your
        Payment Account (payment initiation services).
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.3. The terms and conditions that apply to
        the Payment Accounts that you access through our Services (the "
        <b><span style={{ border: 'none windowtext 1.0pt', padding: '0in' }}>Account Terms</span></b>
        ")
        will remain in effect and this Agreement does not change your Account Terms.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.4. We will not charge you for the use of our
        Services. Applications or other third parties may, however, charge you for products
        and services provided to you that make use of the Services provided by Plaid
        under this Agreement. Application providers and other third parties may pay us
        fees and other amounts in connection with the services we provide to them.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}><u><span lang="EN-GB">Account information services</span></u></p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.5. Plaid's account information services
        allow you to access and view information relating to your selected Payment
        Account within the Plaid dashboard (available at
      </span>
      <a href="https://my.plaid.com"><span lang="EN-GB" style={{
       border: 'none windowtext 1.0pt', padding: '0in'
      }}>{' '}https://my.plaid.com/</span></a>
      <span lang="EN-GB">
        ) and the Application through which you have accessed our
        Services.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.6. To access the Plaid dashboard, you need
        to create an account with Plaid. You also need to ensure that your information
        is accurate, complete and up-to-date. You must follow best practices to help
        secure your Plaid account and notify us if you learn of any unauthorised access
        to or use of your Plaid account.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.7. With your explicit consent, we access and
        display information relating to your selected Payment Account(s) ("
        <b><span style={{ border: 'none windowtext 1.0pt', padding: '0in' }}>Account Information</span></b>
        ")
        within the Plaid dashboard and transmit such information to the Application
        through which you have accessed our Services. Such information may include: a.
        financial transaction history, for example, transaction amount, data,
        description and currency; b. financial account details, for example, account
        number, type, currency and balance; and c. financial account holder
        information, for example, name, address, phone number, and email address.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.8. Before receiving Plaid's account
        information service, you will have instructed the Application through which you
        have accessed our Services to retrieve your Account Information using Plaid.
        Upon such an instruction, you will generally be redirected to us.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.9. Once you are redirected to us, we will
        ask you to select which Payment Account provider ("
        <b><span style={{ border: 'none windowtext 1.0pt', padding: '0in' }}>Account</span></b>

        <b><span style={{ border: 'none windowtext 1.0pt', padding: '0in' }}>Provider</span></b>
        ") you
        would like us to access Account Information from. You will give your explicit
        consent to us: (i) accessing your Account Information from the Account Provider
        you have selected; and (ii) taking the steps outlined in clause paragraph 2.12.
        once we have accessed your Account Information. Plaid will request your Account
        Information from your Account Provider on a periodic basis in accordance with
        your consent until the consent expires or is withdrawn.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.10. Please note, you may be redirected to
        your Account Provider's website or mobile application in order to authenticate
        yourself so that your Account Provider knows that you consent to us accessing
        your Account Information.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.11. Alternatively, in some circumstances, we
        may ask you to provide us with the login details for your Payment Account. By
        providing us with your Payment Account login details, you confirm that you have
        all the necessary rights, permissions and authority to share your login details
        and Account Information with us, and you grant us explicit consent to use your
        login details to access your Payment Account to obtain the necessary Account
        Information on your behalf and at your direction in order to provide you with
        the Services.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.12. Once we have accessed your Account
        Information we may: a. share your Account Information with the Application
        through which you have accessed our Services; b. share your Account Information
        with third parties as directed by the Application through which you have
        accessed our Services provided, however, that you have explicitly consent to
        such sharing; or c. display your Account Information on the Plaid dashboard.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.13. You agree to us sharing the Account
        Information we have accessed from your Account Provider with the Application
        through which you have accessed our Services, and with third parties as
        directed by that Application provided that in both cases you have explicitly
        consented to such sharing. Such sharing allows the Application to provide you
        with products and services in accordance with the Application Terms.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.14. We do not check the accuracy of the
        Account Information retrieved from your Account Provider and we rely on your
        Account Provider to ensure that your Account Information is up to date and
        accurate.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.15. We may standardize, categorize, merge,
        aggregate, and otherwise process your Account Information before displaying
        your Account Information on the Plaid dashboard, sharing it with the
        Application through which you have accessed our Services, or sharing it with
        third parties as directed by that Application with your explicit consent.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.16. You may withdraw or vary your consent
        for Plaid to provide account information services at any time.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}><u><span lang="EN-GB">Payment initiation services</span></u></p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.17. Plaid's payment initiation service allows
        you to make online payments from your Payment Account(s).
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.18. Before receiving our payment initiation
        service, you will have instructed the Application through which you have
        accessed our Services to make an online payment using Plaid. You will be asked
        to review and confirm your payment order details, including: (i) the payment
        amount and (ii) the recipient details. It is your responsibility to ensure that
        all of the payment order details are correct before you confirm the payment
        order. You may not be able to recover a payment to an incorrect account or
        recipient.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.19. These details will be pre-populated for
        you to review and confirm. For example, where you use Plaid payment initiation
        services to purchase goods or services online you must ensure that the merchant
        recipient details are correct.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.20. You will confirm the payment order and
        provide explicit consent for Plaid to send the payment order to your Account
        Provider for execution, to receive information from your Account Provider on the
        initiation and execution of the payment order and to pass this information on
        to the Application through which you accessed our Services.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.21. When you use Plaid's payment initiation
        service you will be asked to select the Payment Account from which you will
        fund the payment and you may be redirected to your Account Provider's website
        or mobile application in order to authenticate yourself so that your Account
        Provider knows that you consent to the transaction.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.22. If the payment order relates to a transaction
        that is to be executed by your Account Provider immediately, you will not be
        able to cancel the payment order once you have provided your confirmation and
        explicit consent in accordance with paragraph 2.20.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.23. If the Application through which you
        accessed our Services supports refunds, the following provisions apply: At the
        time we submit the payment order to your Account Provider for execution, we
        shall ask the Account Provider to share your name, Payment Account sort code
        and account number or IBAN ("
        <b><span style={{ border: 'none windowtext 1.0pt', padding: '0in' }}>Account Details</span></b>
        ") with us. If you require a refund
        for the payment made using Plaid's payment initiation services, you must
        request the refund from the Application through which you accessed our
        Services. The Application will in turn notify us that you have requested a
        refund and we will share your Account Details with the Application in order for
        them to make the refund to your Payment Account (i.e. the Payment Account used
        to fund the original payment). The Application (and not Plaid) is responsible
        for making the refund. Plaid accepts no liability for the accuracy of your
        Account Details provided to us by your Account Provider.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.24. You can use Plaid's payment initiation
        service to submit a standing order to your Account Provider. A standing order
        is an instruction to your Account Provider to make regular, recurring payments
        of the same amount to the same recipient over a specified or indefinite period
        of time.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.25. If you use Plaid's payment initiation
        service to submit a standing order you will be asked to confirm: (i) the amount
        of the periodic payment, (ii) the recipient of the payments, (iii) the payment
        start date, (iv) the interval of the payment (e.g. every week or every month)
        (v) the day on which the payment should be made (e.g. the day of the week or
        month) and (vi) the end date or that the payments will continue unless
        cancelled.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.26. Once you have confirmed the standing
        order details, you will be redirected to your bank to authenticate yourself and
        Plaid will submit the standing order instruction to your Account Provider.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.27. Once the standing order has been
        confirmed as received by your Account Provider, Plaid no longer has control
        over the execution of the standing order payments; this is the responsibility
        of your Account Provider.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.28. You will be able to view your standing
        order via your Account Provider's online banking facility (where supported). If
        you wish to change or cancel a standing order, you must do so directly with
        your Account Provider before the deadline provided by your Account Provider and
        before the next payment is due. If you miss the deadline for cancelling or
        amending your standing order, your Account Provider may not be able to stop the
        next payment in time.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        2.29. For further information on how you can
        cancel and amend standing orders you should refer to your Account Provider
        terms and conditions.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <b>
        <span lang="EN-GB" style={{ color: '#383838', border: 'none windowtext 1.0pt', padding: '0in' }}>
          3.
          Eligibility and Availability
        </span>
      </b>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}><span lang="EN-GB">3.1. You can only use the Services if:</span></p>
    <ul style={{ marginTop: '0in' }} type="disc">
      <li className="MsoNormal"
          style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
        <span lang="EN-GB">
          (a) you
          are 18 or over;
        </span>
      </li>
      <li className="MsoNormal"
          style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
        <span lang="EN-GB">
          (b)
          your Payment Account and Account Provider are located in the UK;
        </span>
      </li>
      <li className="MsoNormal"
          style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
        <span lang="EN-GB">
          (c) you
          provide us with accurate, complete, and up-to-date information, and do not
          misrepresent your identity or any other information about you;
        </span>
      </li>
      <li className="MsoNormal"
          style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
        <span lang="EN-GB">
          (d) you
          agree to this Agreement, and to using our Services in accordance with this
          Agreement; and
        </span>
      </li>
      <li className="MsoNormal"
          style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
        <span lang="EN-GB">
          (e) you
          agree to comply with all laws and regulations applicable to your use of
          the Services.
        </span>
      </li>
    </ul>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        3.2. The Services that are available and the
        manner in which the Services are delivered may vary depending on the type of
        device you are using, the type of Payment Account(s) you have, the Account
        Terms and the Application Terms.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <b>
        <span lang="EN-GB" style={{ color: '#383838', border: 'none windowtext 1.0pt', padding: '0in' }}>
          4.
          Communication
        </span>
      </b>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        4.1. Where relevant, Plaid may send you
        information and notifications to your email address or mobile number via SMS
        where Plaid considers this appropriate.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        4.2. If your contact details (including your
        mobile phone number or email address) change, you should tell us as soon as you
        can. You are responsible for maintaining and regularly checking your device or
        email inbox for information and notifications from Plaid.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        4.3. You can contact Plaid by emailing us
        at
      </span>
      <a href="mailto:regulatory@plaid.com"><span lang="EN-GB" style={{
        border: 'none windowtext 1.0pt', padding: '0in'
      }}>{' '}regulatory@plaid.com</span></a>
      <span lang="EN-GB">.</span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <b>
        <span lang="EN-GB" style={{ color: '#383838', border: 'none windowtext 1.0pt', padding: '0in' }}>
          5.
          Incorrect or Unauthorised Payments
        </span>
      </b>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        5.1. If you suspect that an incorrect or
        unauthorised payment has been made using Plaid's payment initiation services
        you must contact us as soon as possible by emailing
      </span>
      <a href="mailto:security@plaid.com"><span lang="EN-GB" style={{
        color: '#111111', border: 'none windowtext 1.0pt', padding: '0in'
      }}>security@plaid.com</span></a>
      <span lang="EN-GB">.</span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        5.2. You may be entitled to a refund of the
        incorrect or unauthorised payment from your Account Provider provided that you
        notify them of the incorrect or unauthorised payment without delay and in any
        event no later than 13 months after the date of the relevant payment. You must contact
        your Account Provider in the manner set out in the applicable Account Terms.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        5.3. You should be aware that your Account
        Provider may contact you directly (and not through Plaid or the Application
        through which you have accessed our Services) if there is an issue with a
        payment order submitted through Plaid for whatever reason (for example, if
        there are insufficient funds or an issue with your authorisation). You may need
        to resolve such matters directly with your Account Provider.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <b>
        <span lang="EN-GB" style={{ color: '#383838', border: 'none windowtext 1.0pt', padding: '0in' }}>
          6.
          Data
        </span>
      </b>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        6.1. We use your information in line with our
        End User Privacy Policy (“
        <b><span style={{ border: 'none windowtext 1.0pt', padding: '0in' }}>Privacy Policy</span></b>
        ”) which can be found on our website
        at
      </span>
      <a href="https://plaid.com/legal/#end-user-privacy-policy"><span lang="EN-GB" style={{
        color: '#111111', border: 'none windowtext 1.0pt', padding: '0in'
      }}>https://plaid.com/legal/#end-user-privacy-policy</span></a>
      <span lang="EN-GB">
       .If you are not comfortable with how we
        handle your information as explained in the Privacy Policy, you should not use
        our Service.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <b>
        <span lang="EN-GB" style={{ color: '#383838', border: 'none windowtext 1.0pt', padding: '0in' }}>
          7.
          Liability
        </span>
      </b>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        7.1. Plaid will not be liable to refund you
        for any losses caused by circumstances beyond our control, for example, due to
        extreme weather, terrorist activity or industrial action.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        7.2. Plaid is not liable for any contravention
        of a requirement imposed on it by or under Part 7 of the Payment Services
        Regulations 2017 where the contravention is due to: a. abnormal or
        unforeseeable circumstances beyond Plaid's control, the consequences of which
        would have been unavoidable despite all efforts to the contrary; or b. the
        obligations of Plaid under other provisions of EU or national law.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        7.3. Nothing excludes or limits our liability
        for: a. death or personal injury caused by our negligence; b. our fraud or
        fraudulent misrepresentation; or c. a deliberate breach of this Agreement in a
        major way that is designed to harm you.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        7.4. We are not liable to you for any harm,
        damage or loss to you arising from the acts or omissions of any third parties,
        in particular your Account Provider(s) and the Application through which you
        have accessed our Services.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <b>
        <span lang="EN-GB" style={{ color: '#383838', border: 'none windowtext 1.0pt', padding: '0in' }}>
          8.
          Complaints
        </span>
      </b>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        8.1. If you have a complaint about our
        Services, please email us at
      </span>
      <a href="mailto:regulatory@plaid.com"><span lang="EN-GB" style={{
        border: 'none windowtext 1.0pt', padding: '0in'
      }}>{' '}regulatory@plaid.com </span></a>
      <span lang="EN-GB">
        so that Plaid can investigate the
        circumstances for you. We will aim to deal quickly and fairly with any
        complaints you have about our Services in accordance with our obligations under
        applicable law. Plaid may, however, direct you to: a. your Account Provider, if
        your complaint relates to the services provided under the Account Terms or
        involves an incorrect or unauthorised payment in accordance with paragraph 5
        above; or b. the Application through which you accessed our Services, if your
        complaint relates to the products and/or services provided by the Application
        under the Application Terms.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        8.2. If your complaint relates to our Services
        and we do not resolve it, you may be able to refer it to the UK Financial
        Ombudsman Service. You can contact the UK Financial Ombudsman by telephone on:
        from inside the UK: 0300 123 9123 or 0800 023 4567; from other countries: +44
        20 7964 0500 on Monday to Friday, 8am to 8pm and on Saturday 9am to 1pm; by
        post at The Financial Ombudsman Service, Exchange Tower, London E14 9SR; or by
        email:
      </span>
      <a href="mailto:regulatory@plaid.com"><span lang="EN-GB" style={{
      border: 'none windowtext 1.0pt', padding: '0in'
      }}>{' '}complaint.info@financial-ombudsman.org.uk</span></a>
      <span lang="EN-GB">
       .The UK Financial Ombudsman Service is also
        available in a number of different languages and if you need it you will be put
        in touch with a translator when you contact the UK Financial Ombudsman Service.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        8.3. Plaid is not responsible for any
        complaints or disputes about purchases made using our payment initiation
        services. You should settle these with the person from whom you bought the
        goods or services. We are not responsible for the quality, safety, legality or
        any other aspect of any goods or services purchased using our payment
        initiation services. Remember that once you have used our payment initiation service
        to make a purchase, Plaid cannot cancel or stop that payment transaction.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        8.4. Plaid is also not responsible for any
        complaints or disputes about products and/or services provided by the
        Application through which you accessed our Services, other third parties, or
        your Account Provider. You should settle these with the Application, third
        party, or Account Provider directly.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <b>
        <span lang="EN-GB" style={{ color: '#383838', border: 'none windowtext 1.0pt', padding: '0in' }}>
          9.
          Changes to this Agreement
        </span>
      </b>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        9.1. The contract between you and Plaid which
        is set out in this Agreement and which governs your use of the Services will
        continue until cancelled in accordance with this paragraph 9.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        9.2. We will give you at least two months'
        prior written notice via, at a minimum, the contact details you have supplied
        to us of any intended material change to this Agreement along with the new
        version of the Agreement.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        9.3. If you do not agree with the proposed
        change(s) you must tell us using the Plaid contact details set out in paragraph
        4.3. before that change takes effect and you will have the right to terminate
        this Agreement at any time before the proposed date of their entry into force.
        If you do not contact us in order to tell us that you do not accept the changes
        and request to terminate this Agreement you will be deemed to have accepted the
        change(s) to the Agreement.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <b>
        <span lang="EN-GB" style={{ color: '#383838', border: 'none windowtext 1.0pt', padding: '0in' }}>
          10.
          Termination
        </span>
      </b>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        10.1. You have the right to cancel the
        contract between us, which is set out in this Agreement, at any time without
        notice by contacting Plaid using the Plaid contact details set out at paragraph
        4.3.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        10.2. We may cancel the contract with you,
        with immediate effect, by giving written notice: a. if you repeatedly break
        this Agreement and fail to resolve the matter to Plaid's satisfaction in a
        timely manner; or b. in the event of your death or incapacity.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        10.3. We may cancel this Agreement with you
        for any reason by giving you at least 2 months' written notice.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <b>
        <span lang="EN-GB" style={{ color: '#383838', border: 'none windowtext 1.0pt', padding: '0in' }}>
          11.
          Governing law and language
        </span>
      </b>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        11.1. This Agreement is governed by English
        law and is subject to the non-exclusive jurisdiction of the English Courts.
      </span>
    </p>
    <p className="MsoNormal"  style={{
      background: 'white', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <span lang="EN-GB">
        11.2. This Agreement is in English and all
        communications with you will be in English.
      </span>
    </p>
    <span lang="EN-GB" style={{ fontSize: '10.0pt', fontFamily: 'Neue Haas Grotesk Display Pro' }}>
      <br clear="all" style={{ pageBreakBefore: 'always' }}/>
    </span>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}><b><span
      lang="EN-GB" style={{ textTransform: 'uppercase' }}></span></b></p>
    <p className="DocumentTitle"><span lang="EN-GB">annex 3 – e-money account agreement</span></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>
        Important information you
        need to know
      </b>
    </p>
    <p className="MsoNormal"  style={{ punctuationWrap: 'hanging' }}></p>
    <p className="MsoNormal"  style={{ punctuationWrap: 'hanging' }}>
      These
      End User PIS Terms and Conditions (“
      <b>Terms and Conditions</b>
      ”) form the
      agreement between Modulr FS and you and sets out the terms that apply to your
      use of PIS as defined below.
    </p>
    <p className="MsoNormal"  style={{ punctuationWrap: 'hanging' }}></p>
    <p className="MsoNormal"  style={{ punctuationWrap: 'hanging' }}>
      Please
      read these Terms and Conditions carefully before you agree to use PIS provided
      by us.
      {' '}
    </p>
    <p className="MsoNormal"  style={{ punctuationWrap: 'hanging' }}></p>
    <p className="MsoNormal"  style={{ punctuationWrap: 'hanging' }}>
      By
      using PIS, you accept the terms of these Terms and Conditions. If there is
      anything you do not understand, please contact Customer Services using the
      contact details set out below. You can also request a copy of these Terms and
      Conditions at any time by contacting Customer Services.
    </p>
    <p className="MsoNormal"  style={{
      punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other'
    }}></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}><b>1.
      DEFINITIONS</b></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>
        Account Servicing Payment
        Service Provider
        {' '}
      </b>
      or
      <b> ASPSP</b>
      {' '}
      – the third party payment service
      provider, such as a bank, with whom you hold an online payment account (Source
      Account) which we will access when you use our Service.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>Customer Services</b>
      {' '}
      -
      the contact centre for dealing with queries about our Services, who can be
      contacted at
      {' '}
      <span lang="EN-GB"><a href="mailto:support@modulrfinance.com"><span lang="EN-US">support@modulrfinance.com</span></a></span>
      {' '}
      or 0303 313 0060.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>Data Protection Laws</b>
      {' '}
      –
      the following, to the extent they are applicable to a party: the General Data
      Protection Regulation (EU) 2016/679, the Electronic Communications Data
      Protection Directive 2002/58/EC, the Privacy and Electronic Communications (EC
      Directive) Regulations 2003 and all applicable laws and regulations relating to
      processing of personal data and privacy (as amended or replaced from time to
      time), including where applicable the guidance and codes of practice issued by
      the Information Commissioner (in the United Kingdom) or other applicable
      supervisory authority.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>Information </b>
      – means
      any personal information related to you
      <b> </b>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>Merchant - </b>
      the entity
      legally responsible for the Modulr Account, from whom you are purchasing goods
      or services.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>Modulr – </b>
      Modulr
      Finance Ltd,
      <b> </b>
      a company registered in England and Wales with number
      09897957 and whose registered office is at Scale Space, 58 Wood Lane, London,
      W12 7RZ.Modulr Finance Ltd (FRN: 900699) is a registered agent of Modulr FS
      Ltd.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>Modulr Account</b>
      {' '}
      - the
      electronic account provided by us to the Merchant.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>Payment Initiation Service</b>
      or
      {' '}
      <b>PIS </b>
      – means a service to initiate a payment at your request from a
      Source Account held by you, as more particularly described in clause 3.1.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>Source Account</b>
      {' '}
      – a
      payment account accessible online which you hold with an ASPSP;
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>Standing Order</b>
      {' '}
      - a regular,
      recurring Payment Initiation as instructed by you.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>we, us, our or Modulr FS</b>
      {' '}
      - Modulr FS Ltd, a company registered in England and Wales with number 09897919
      and whose registered office is at Scale Space, 58 Wood Lane, London, W12 7RZ
      and who is regulated by the Financial Conduct Authority for issuance of
      electronic money under FRN 900573, or Modulr acting on Modulr FS Ltd’s behalf.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>you, your</b>
      {' '}
      - the payee
      who wishes to use PIS to send funds to a Merchant’s Modulr Account.
    </p>
    <p className="MsoNormal"  style={{
      punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other'
    }}></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>
        2. SCOPE OF THESE TERMS AND
        CONDITIONS
      </b>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>2.1. </b>
      These Terms and
      Conditions apply to your use of PIS. We are authorised by the Financial Conduct
      Authority (FRN 900573) for the issuance of electronic money and providing
      payment services, including payment initiation services. Your rights and
      obligations relating to the use of PIS are subject to these Terms and
      Conditions between you and us.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>2.2. </b>
      These Terms and
      Conditions are written and available only in English and we undertake to
      communicate with you in English regarding any aspect of your use of PIS.
    </p>
    <p className="MsoNormal"  style={{
      punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other'
    }}></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}><b>3.
      OUR SERVICES</b></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>3.1. </b>
      You will be able
      to initiate a payment from your Source Account to the Merchant’s Modulr
      Account, or set up a Standing Order. You will need to select the linked Source
      Account and the amount when you ask us to initiate the payment.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>3.2.</b>
      {' '}
      We may use
      internet providers, web browsers and other third parties to access your Source
      Account held with the ASPSP, so that we can transmit the information relating
      to your Source Account or initiate the payment.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>3.3. </b>
      The services provided
      to you in relation to your Source Account are subject to a separate agreement
      between you and the ASPSP. We are not responsible for the services or provided
      to you by the ASPSP.
    </p>
    <p className="MsoNormal"  style={{
      punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other'
    }}></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}><b>4.
      USING THE SERVICES</b></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>4.1. </b>
      Each time you use
      our PIS, you are giving us your explicit consent to initiate the payment from
      your Source Account to the Modulr Account for the amount you select at the time
      of your request.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>4.2.</b>
      {' '}
      You will need to
      provide the same identifying information that you use when logging into to
      access your Source Account online with your ASPSP, such as your user name,
      password and/or other security information (such as answers to challenge
      questions or one-time-passcodes) (“
      <b>Security Credentials</b>
      ”). You will need
      to provide your Security Credentials to:
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>4.2.1.</b>
      {' '}
      link the Source
      Account; and
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>4.2.2.</b>
      authorise a
      payment from your Source Account you have asked us to initiate.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>4.3. </b>
      We will not be
      able to revoke a transfer from your Source Account once you have confirmed it.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>4.4.</b>
      {' '}
      Once you request
      us to initiate a payment from your Source Account, the Merchant will provide:
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>4.4.1.</b>
      {' '}
      a confirmation
      that the payment has been successfully initiated with your ASPSP;
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>4.4.2.</b>
      {' '}
      a reference to
      identify the payment transaction and any information transferred with the
      payment order; and
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>4.4.3.</b>
      {' '}
      the amount of
      payment.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}><b>5.
      STANDING ORDERS</b></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>5.1. </b>
      You will be able
      to set up a Standing Order to make regular, recurring Payment Initiations to
      the Merchant’s Modulr Account. When setting up a Standing Order, you are giving
      us explicit consent to initiate a regular, recurring Payment Initiations from
      your Source Account to the Modulr Account for the amount you select at the time
      of your request.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>5.2.</b>
      {' '}
      You must provide
      the following information to allow us to set up the Standing Order: the date of
      the first Payment Initiation, the amount of the Standing Order, and if the
      Standing Order is not open-ended, the date of the final Payment Initiation.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>5.3.</b>
      {' '}
      If you wish to
      amend or cancel a Standing Order, you must contact your ASPSP. Modulr is not
      able to amend or cancel Standing Orders and accepts no liability for Standing
      Orders which have been incorrectly amended or cancelled.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}><b>6.
      FEES</b></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      We will not charge you any
      fees for using PIS.
      {' '}
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
     </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}><b>7.
      SECURITY</b></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>7.1.</b>
      {' '}
      You must not:
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>7.1.1. </b>
      allow another
      person to use security information necessary to use PIS;
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>7.1.2. </b>
      write down
      password(s) or any security information unless this is done in a way that would
      make it impossible for anyone else to recognise any of that information; or
      <br/>
      <b>7.1.3. </b>
      disclose passwords or any security information, or otherwise make
      them available to any other person, whether verbally or by entering them in a
      way that allows them to be observed by others.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>7.2.</b>
      {' '}
      We can restrict or
      suspend your ability to use PIS, including refusing to initiate the payment
      from your Source Account, if we are concerned that using PIS is causing or
      could cause a breach of these Terms and Conditions or if we have reasonable
      grounds for suspecting that you or a third party has committed or is about to
      commit a crime or other abuse in connection with your use of our PIS.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>7.3.</b>
      {' '}
      If we refuse to
      initiate the payment from your Source Account, we will, without undue delay and
      provided we are legally permitted to do so, notify you, via the Merchant.If
      possible, we will provide the reasons for this and where it is possible will
      provide reasons for the restriction or suspension and where those reasons
      relate to factual matters, the procedure of rectifying any factual errors that
      led to the restriction or suspension.
    </p>
    <p className="MsoNormal"  style={{
      punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other'
    }}></p>
    <p className="MsoNormal"  style={{
      pageBreakAfter: 'avoid', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <b>
        8.
        DISPUTES AND INCORRECT TRANSACTIONS
      </b>
    </p>
    <p className="MsoNormal"  style={{
      pageBreakAfter: 'avoid', punctuationWrap: 'hanging',
      textAutospace: 'ideograph-numeric ideograph-other'
    }}>
      <b>8.1 </b>
      <span style={{ color: 'black' }}>
        If you have a reason to believe that a payment from
        your Source Account initiated using our PIS was unauthorised or was made
        incorrectly
      </span>
      ,
      <span style={{ color: 'black' }}>
        you can contact your ASPSP to
        resolve your query and we will cooperate with the ASPSP’s investigation in to
        such payment.Where we believe that the incorrect payment was due to our error
        in respect of the part of the initiated payment we were responsible for, we
        will refund the incorrectly initiated payment back to the original Source
        Account.
        {' '}
      </span>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>8.2. </b>
      You will be
      liable for all payments initiated through our PIS if you have acted
      fraudulently or with gross negligence (for example failed to keep your security
      information or Source Account Security Credentials safe).
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>8.3. </b>
      In circumstances
      where payment is initiated from your Source Account by us is disputed by you or
      your ASPSP, if we require your support to enable us to establish the cause of
      the incorrectly initiated payment, you agree to provide us with all assistance
      that we reasonably require.
    </p>
    <p className="MsoNormal"  style={{
      punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other'
    }}></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}><b>9.
      YOUR LIABILITY</b></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>9.1.</b>
      {' '}
      You are
      responsible for understanding and complying with these Terms and Conditions.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>9.2.</b>
      {' '}
      It is your
      responsibility to keep the Merchant updated of changes to your Information,
      including e-mail address and mobile numbers. Failure to do so may result in us being
      unable to contact you regarding our PIS or to let you know about changes to
      these Terms and Conditions.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>9.3.</b>
      {' '}
      You agree to
      indemnify and hold harmless, us, Modulr and our distributors, partners, agents,
      sponsors, and service providers and their group companies from and against the
      costs of any legal action taken to enforce these Terms and Conditions and/or
      any breach of these Terms and Conditions by you.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}><b>10.
      OUR LIABILITY</b></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>10.1.</b>
      {' '}
      Our liability in
      connection with this these Terms and Conditions (whether arising in contract,
      tort (including negligence), breach of statutory duty or otherwise) shall be
      subject to the following exclusions and limitations:
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>10.1.1.</b>
      {' '}
      we shall not be
      liable for any default resulting directly or indirectly from any cause beyond
      our control;
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>10.1.2.</b>
      {' '}
      we shall not be
      liable for any loss of profits, loss of business, or any indirect,
      consequential, special or punitive losses;
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>10.1.3.</b>
      {' '}
      where payment
      is incorrectly initiated from your Source Account due to our default, our
      liability shall be limited to refund to you of an equivalent amount to that
      which was incorrectly deducted from your Source Account.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>10.2.</b>
      {' '}
      Nothing in these
      Terms and Conditions shall exclude or limit our liability for death or personal
      injury resulting from our negligence or fraud.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>10.3.</b>
      {' '}
      To the extent
      permitted by law, all conditions or warranties implied by law, statute or
      otherwise are expressly excluded.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>10.4.</b>
      {' '}
      The above
      exclusions and limitations set out in this paragraph shall apply to any
      liability of our affiliates and other suppliers, contractors, agents or
      distributors and any of their respective affiliates (if any), to you, which may
      arise in connection with these Terms and Conditions.
    </p>
    <p className="MsoNormal"  style={{
      punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other'
    }}></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}><b>11.
      VARIATION</b></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>11.1.</b>
      {' '}
      Each time you use
      our PIS you will be bound by the Terms of Service in force at that time.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>11.2.</b>
      {' '}
      From time to
      time, we may update these Terms and Conditions. If we do this then we will
      provide an updated copy to Merchants and you will be bound by those new terms
      the next time you use our PIS. If you do not agree to those changes you should
      not use our PIS.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>11.3.</b>
      {' '}
      If any part of
      these Terms and Conditions are inconsistent with any legal requirements then we
      will not rely on that part but treat it as if it did actually reflect the
      relevant legal requirement. If we need to make operational changes before we
      can fully comply with the new regulatory requirement, we will make those
      changes as soon as reasonably practical.
    </p>
    <p className="MsoNormal"  style={{
      punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other'
    }}></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>
        12. TERMINATION OR
        SUSPENSION
      </b>
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>12.1.</b>
      {' '}
      We may at any
      time terminate or withhold your access to all or any part of our PIS at any
      time, effective immediately:
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>12.1.1.</b>
      {' '}
      if you
      have breached any provision of these Terms and Conditions (or have acted in a
      manner which clearly shows that you do not intend to, or are unable to comply
      with the provisions of these Terms and Conditions); or
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>12.1.2.</b>
      {' '}
      if we, in our
      sole discretion, believe we are required to do so by law (for example, where
      the provision of the PIS to you is, or becomes, unlawful).
    </p>
    <p className="MsoNormal"  style={{
      punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other'
    }}></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}><b>13.
      YOUR INFORMATION</b></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>13.1.</b>
      {' '}
      You may provide
      us with your Information from time to time in connection with your use of PIS.
      Some Information, especially the Account Information, will be necessary for us
      to provide you with the PIS under these Terms and Conditions.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>13.2.</b>
      {' '}
      We and our
      affiliates are committed to maintaining your Information in accordance with the
      requirements of the Data Protection Laws. You acknowledge and agree that any
      Information provided by you or a third party on your behalf to us shall be
      used, kept and may be disclosed to third parties in accordance with our Privacy
      Policy which is available on our website. We will take all reasonable steps to
      ensure that your Information is kept secure against unauthorised access, loss,
      disclosure or destruction. Except as required by law, or in accordance with
      these Terms and Conditions, your Information will not be passed to anyone
      without your permission.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>13.3.</b>
      {' '}
      You explicitly
      consent to us accessing, processing and retaining any Information you provide
      to us for the purposes of providing payment services to you. This does not
      affect any rights and obligations you or we have under Data Protection Laws.
      You agree that we can use your Information in connection with the PIS, to
      enable us to review, develop and improve our products and services. This may
      involve providing your Information to our partners, affiliates, agents,
      distributors and suppliers to process transactions and for their statistical
      research and analytical purposes. We may also disclose your Information as
      required by law, regulation or any competent authority or agency to investigate
      possible fraudulent, unlawful or unauthorised activity. You may withdraw your
      consent at any time.If you do this, we will stop providing our PIS to you and
      stop using your Information to provide payment services to you.We may
      continue to process your Information for other purposes, for example where we
      are required by law to do so.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>13.4.</b>
      {' '}
      If we discover
      that the Information we hold about you is incorrect, we may have to suspend or
      cancel your access to the PIS until we can establish the correct Information,
      in order to protect us both.
    </p>
    <p className="MsoNormal"  style={{
      punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other'
    }}></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}><b>14.
      COMPLAINTS PROCEDURE</b></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>14.1</b>
      {' '}
      Complaints
      regarding any element of the PIS provided by us can be sent to Customer
      Services.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>14.2.</b>
      {' '}
      All complaints
      will be subject to our complaints procedure. We will provide you with a copy of
      our complaints procedure upon request and, if we receive a complaint from you,
      a copy of our complaints procedure will automatically be posted or emailed to
      you.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>14.3. </b>
      In most cases we
      will provide a full response by email to your complaint within fifteen business
      days after the date we receive your complaint.In exceptional circumstances
      where we are unable to respond in full to your complaint, we will inform you of
      this giving our reasons for the delay and the timeframe within which you will
      receive a full reply, which in any event shall be within thirty-five days of
      the date we received your complaint.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>14.4.</b>
      {' '}
      If we fail to
      resolve your complaint to your satisfaction you may refer your complaint to the
      Financial Ombudsman Service (Exchange Tower, London E14 9SR, phone 0800 023
      4567). Details of the service offered by the Financial Ombudsman Service are
      available at www.financial-ombudsman.org.uk.
    </p>
    <p className="MsoNormal"  style={{
      punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other'
    }}></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}><b>15.
      GENERAL</b></p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>15.1.</b>
      {' '}
      Any delay or
      failure to exercise any right or remedy under these Terms and Conditions by us
      shall not be construed as a waiver of that right or remedy or preclude its
      exercise at any subsequent time.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>15.2.</b>
      {' '}
      If any provision
      of these Terms and Conditions is deemed unenforceable or illegal, the remaining
      provisions will continue in full force and effect.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>15.3.</b>
      {' '}
      You may not
      assign or transfer any of your rights and/or benefits under these Terms and
      Conditions and you shall be the sole party to the contract between us. We may
      assign our rights and benefits at any time without prior written notice to you.
      We may subcontract any of our obligations under these Terms and Conditions.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>15.4.</b>
      {' '}
      Save for Modulr,
      who act on our behalf, no third party who is not a party to these Terms and
      Conditions has a right to enforce any of the provisions in these Terms and
      Conditions.
    </p>
    <p className="MsoNormal"
       style={{ punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
      <b>15.6.</b>
      {' '}
      These Terms and
      Conditions are governed by English law and you agree to the exclusive
      jurisdiction of the courts of England and Wales.
    </p>
    <p className="MsoNormal" align="center"
       style={{ textAlign: 'center', punctuationWrap: 'hanging', textAutospace: 'ideograph-numeric ideograph-other' }}>
    </p>
  </Styled>

)

export default memo(TemplateComponent)
