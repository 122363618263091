import React, { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { ContainerStyled, CardStyled, LogoWrapper, SpiralStyled, TextStyled, TitleStyled } from './styled'
import { PlendLogoIcon } from '../../../../core/components/icons/PlendLogoIcon'
import PendingLoan from './loanStatusTemplate/PendingLoan'
import ApprovedLoan from './loanStatusTemplate/ApprovedLoan'
import DeclinedLoan from './loanStatusTemplate/DeclinedLoan'
import { TState, TStatus } from '../../types'
import Spiral from '../../../../core/components/icons/svg/Spiral.svg'

const statusMap: {[keyof in TStatus ]: ReactNode} = {
  hasPendingLoan: <PendingLoan />,
  hasApprovedLoan: <ApprovedLoan/>,
  hasDeclinedLoan: <DeclinedLoan/>
}

const DefaultLoanStatus = () => {
  const { state } = useLocation() as TState

  return (
    <ContainerStyled>
      <CardStyled>
        <LogoWrapper>
          <PlendLogoIcon/>
        </LogoWrapper>
        <TitleStyled>
          Hey
          {' '}
          {state?.borrowerName}
          ,
        </TitleStyled>
        {state?.currentStatus && statusMap[state?.currentStatus]}
        <TextStyled>
          <SpiralStyled src={Spiral} alt="spiral"/>
        </TextStyled>
      </CardStyled>
    </ContainerStyled>

  )
}

export default DefaultLoanStatus
