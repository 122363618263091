/* eslint-disable react/no-unescaped-entities */

import styled from 'styled-components/macro'
import { memo } from 'react'

const TextStyled = styled.div`
  margin-top: 30px;
`
const ApprovedLoan = () => (
  <>
    <TextStyled>
      <div>
        Thank you for your recent application for a Plend loan.
      </div>
      <div>
        It looks like you already have an outstanding loan with Plend, according to our records.
      </div>
    </TextStyled>
    <TextStyled>
      Unfortunately we cannot consider you for a second Plend loan until you've repaid
      the first six monthly instalments on your original loan.
    </TextStyled>
    <TextStyled>
      If you believe our records are incorrect, and you do not already have a loan with us,
      please get in touch with us at
      {' '}
      <a href="mailto:support@plend.co.uk">support@plend.co.uk</a>
      .
    </TextStyled>
    <TextStyled>
      <p>Regards,</p>
      <p>Team Plend</p>
    </TextStyled>
  </>
)

export default memo(ApprovedLoan)
