/* eslint-disable max-len */

import React from 'react'
import styled from 'styled-components/macro'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { InstagramIcon } from '../../../../core/components/icons/InstagramIcon'
import { FacebookIcon } from '../../../../core/components/icons/FacebookIcon'
import { TwitterIcon } from '../../../../core/components/icons/TwitterIcon'
import { LinkedinIcon } from '../../../../core/components/icons/LinkedinIcon'
import { PlendLogoIcon } from '../../../../core/components/icons/PlendLogoIcon'

const ContainerStyled = styled.div`
  ${flex({ direction: 'column', align: 'center' })};
  ${fontFamily('Neue')};
  width: 100%;
  padding: 0 24px;
  background: ${({ theme }) => theme.colors.blackDarkBlue};
  color: ${({ theme }) => theme.colors.white};

  
  @media (max-width: 768px) {
    padding: 32px 20px;
  }
  @media (max-width: 375px) {
    padding: 24px 10px;
  }
`

const NavigationStyled = styled.a`
  &&{
    text-decoration: none ;
    color: ${({ theme }) => theme.colors.white}
  }
`
const AdditionalInfo = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  margin-top: 48px;

  @media (max-width: 768px) {
    ${flex({ direction: 'column' })};
    margin-top: 0;
  }
`

const LogoWrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};
`
const Description = styled.div`
  max-width: 996px;
  width: 100%;
  margin-left: 76px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: .4px;
  
  @media (max-width: 768px) {
    max-width: 728px;
    margin: 16px 0 0;
  }
`

const DescriptionText = styled.p`
  letter-spacing: .4px;
`

const LinksInfo = styled.div`
  ${flex({ justify: 'space-between', align: 'center' })};
  max-width: 1200px;
  width: 100%;
  margin: 20px 0 32px;
  
  @media (max-width: 768px) {
    ${flex({ direction: 'column' })};
    margin: 16px 0 0;
  }
  
  @media (max-width: 375px) {
    ${flex({ direction: 'column' })};
    margin: 16px 0 0;
  }

`
const SocialNetwokLinks = styled.div`
  & a:not(:last-child) {
    margin-right: 20px;
  }
  
  @media (max-width: 768px) {
    ${flex({ justify: 'center' })};
    margin-top: 20px;
  }
  
  @media (max-width: 375px) {
    ${flex({ justify: 'center' })};
    margin-top: 18px;
  }
`
const AdditionalLinks = styled.div``
const Links = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 10px;

  @media (max-width: 768px) {
    ${flex({ justify: 'center' })};
    margin-top: 20px;
    grid-column-gap: 20px;
  }
  @media (max-width: 375px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 12px;
  }
`

const DefaultFooter = () => (
  <ContainerStyled>
    <AdditionalInfo>
      <LogoWrapper>
        <PlendLogoIcon/>
      </LogoWrapper>
      <Description>
        <DescriptionText>
          Plend is a trading name of Plend Limited. Authorised and regulated by the Financial Conduct Authority: FRN 963328. Registered in England & Wales (company number 12581855).
          Registered office address: Aldgate Tower- 6th Floor, 2 Leman Street, London, E1 8FA. Registered with the Information Commissioner’s Office in compliance with the Data Protection Regulations 2018 under registration ZB037977.
        </DescriptionText>
        <DescriptionText>
          Warning: Late repayment can cause you serious money problems. For help, go to
          {' '}
          <NavigationStyled href="https://moneyadviceservice.org.uk">moneyadviceservice.org.uk</NavigationStyled>
          .
        </DescriptionText>
      </Description>
    </AdditionalInfo>
    <LinksInfo>
      <DescriptionText>
        {`© Plend Limited ${new Date().getFullYear()}. All rights reserved.`}
      </DescriptionText>
      <SocialNetwokLinks>
        <NavigationStyled href="https://www.instagram.com/plend.uk/">
          <InstagramIcon/>
        </NavigationStyled>
        <NavigationStyled href="https://www.facebook.com/Peoplelending/">
          <FacebookIcon/>
        </NavigationStyled>
        <NavigationStyled href="https://twitter.com/Peoplelend">
          <TwitterIcon/>
        </NavigationStyled>
        <NavigationStyled href="https://www.linkedin.com/company/peoplelend/">
          <LinkedinIcon/>
        </NavigationStyled>
      </SocialNetwokLinks>
      <AdditionalLinks>
        <Links>
          <div><NavigationStyled href="https://plend.co.uk/contact-us/">Contact us</NavigationStyled></div>
          <div><NavigationStyled href="https://www.plend.co.uk/privacy-policy/">Privacy Policy</NavigationStyled></div>
          <div><NavigationStyled href="https://plend.co.uk/complaints/">Complaints</NavigationStyled></div>
          <div><NavigationStyled href="https://www.plend.co.uk/terms-and-conditions/">Terms and Conditions</NavigationStyled></div>
        </Links>
      </AdditionalLinks>
    </LinksInfo>

  </ContainerStyled>
)

export default DefaultFooter
